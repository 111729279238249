var notificationsHelper = (function($) {

    'use strict';

    var notificationUpdateTimer = null;

    function init() {

        // Értesítések frissítése belépett felhasználóknál
        clearInterval(window['checkCartContentTimer']);

        if ( $('.js-toolbar-notifications-btn').length > 0 ) {
            window['checkCartContentTimer'] = setInterval(function() {
                updateNotifications();
            }, 30 * 1000); // 30
        }

        $('body').off('click.notification-tab');
        $('body').on('click.notification-tab', '.notifications-dropdown .mdl-tabs__tab-bar a', function (e){
            setTimeout(function() {
                var psContainer = document.querySelectorAll('.js-ps-container');

                [].forEach.call(psContainer, function(el, index) {
                    Ps.destroy(el);
                    Ps.initialize(el, {
                        wheelSpeed: 0.5,
                        wheelPropagation: false,
                        //minScrollbarLength: 20
                    });
                });
            }, 200);
        });

        // Értesítés sorára kattintás
        $('body').off('click.notification-item-row');        
        $('body').on('click.notification-item-row', '.js-notification-item-row', function (e){
            e.preventDefault();

            var _loadContent = ($(this).attr('data-load-content') == '1') ? $(this).attr('href') : '';

            if ( $('.notification-item[data-id="' + $(this).attr('data-id') + '"]').hasClass('is-unread') ) {
                setNotificationRead($(this).attr('data-id'), 'read', $(this).attr('data-tab'), _loadContent);

                $('.notification-item[data-id="' + $(this).attr('data-id') + '"]').removeClass('is-unread');
            } else {
                if ( _loadContent != '' ) {
                    ajaxHelper.loadContent({
                        _url: _loadContent,
                        _force_redirect: '0',
                        _url_title: $(this).attr('data-title'),
                        _callback: '',
                        _container: 'js-main-container',
                        _push_state: 'true',
                        _self: $( $(this).attr('data-menu-item') )
                    });
                }
            }

            $('.js-toolbar-notifications-btn').click();
        });

        // Mindegyik olvasottnak jelölése
        $('body').off('click.notifications-mark-all-read');
        $('body').on('click.notifications-mark-all-read', '.js-notifications-mark-all-read', function (e){
            e.preventDefault();

            setNotificationRead(0, 'read', $(this).attr('data-tab'));

            if ( $(this).attr('data-tab') == 'notification' ) {
                $('#notifications-panel1 .notification-item.is-unread').removeClass('is-unread');
            } else {
                $('#notifications-panel2 .notification-item.is-unread').removeClass('is-unread');
            }
        });

        // Értesítésnél olvasottság állítás a kis karikával
        $('body').off('click.read-it-marker');
        $('body').on('click.read-it-marker', '.read-it-marker', function (e){
            e.preventDefault();

            if ( $(this).hasClass('is-read') ) {
                setNotificationRead($(this).attr('data-id'), 'unread');

                $('.notification-item[data-id="' + $(this).attr('data-id') + '"]').addClass('is-unread');
            } else {
                setNotificationRead($(this).attr('data-id'), 'read');

                $('.notification-item[data-id="' + $(this).attr('data-id') + '"]').removeClass('is-unread');
            }
        });

    }

    function updateNotifications() {

        $.ajax({
            url: GLOBAL_URIS.notifications_update_url,
            type: "GET",
            dataType: "json",
            success: function(data){

                // Olvasatlan üzenetek számának beállítása
                $('.js-sum-unread-number').attr('data-badge', data.sum_unread_number);
                $('.js-notifications-unread-number').html(data.notifications_unread_number);
                $('.js-alerts-unread-number').html(data.alerts_unread_number);

                if ( data.sum_unread_number == 0 ) {
                    $('.js-sum-unread-number').removeClass('mdl-badge mdl-badge--overlap');
                } else {
                    $('.js-sum-unread-number').addClass('mdl-badge mdl-badge--overlap');
                }

                $('.js-notification-list-container').html('');
                $('.js-notification-list-container').append(data.notification_list);

                $('.js-alert-list-container').html('');
                $('.js-alert-list-container').append(data.alert_list);

                if ( data.notifications_unread_number == 0 ) {
                    $('.js-notifications-mark-all-read[data-tab="notification"]').addClass('is-disabled');
                } else {
                    $('.js-notifications-mark-all-read[data-tab="notification"]').removeClass('is-disabled');
                }

                if ( data.alerts_unread_number == 0 ) {
                    $('.js-notifications-mark-all-read[data-tab="alert"]').addClass('is-disabled');
                } else {
                    $('.js-notifications-mark-all-read[data-tab="alert"]').removeClass('is-disabled');
                }
            }
        });
    }

    function setNotificationRead(id, type, tab, load_url) {
        if (typeof load_url === 'undefined') {
            load_url = '';
        }

        $.ajax({
            url: GLOBAL_URIS.notifications_set_read,
            type: "POST",
            dataType: "json",
            data: {
                id: id,
                type: type,
                tab: tab,
            },
            success: function(data){

                $('.js-sum-unread-number').attr('data-badge', data.sum_unread_number);
                $('.js-notifications-unread-number').html(data.notifications_unread_number);
                $('.js-alerts-unread-number').html(data.alerts_unread_number);

                if ( data.sum_unread_number == 0 ) {
                    $('.js-sum-unread-number').removeClass('mdl-badge mdl-badge--overlap');
                } else {
                    $('.js-sum-unread-number').addClass('mdl-badge mdl-badge--overlap');
                }
                
                if ( data.notifications_unread_number == 0 ) {
                    $('.js-notifications-mark-all-read[data-tab="notification"]').addClass('is-disabled');
                } else {
                    $('.js-notifications-mark-all-read[data-tab="notification"]').removeClass('is-disabled');
                }

                if ( data.alerts_unread_number == 0 ) {
                    $('.js-notifications-mark-all-read[data-tab="alert"]').addClass('is-disabled');
                } else {
                    $('.js-notifications-mark-all-read[data-tab="alert"]').removeClass('is-disabled');
                }

                if ( load_url != '' ) {
                    ajaxHelper.loadContent({
                        _url: load_url,
                        _force_redirect: '0',
                        _url_title: $('.js-notification-item-row[data-id="' + id + '"]').attr('data-title'),
                        _callback: '',
                        _container: 'js-main-container',
                        _push_state: 'true',
                        _self: $( $('.js-notification-item-row[data-id="' + id + '"]').attr('data-menu-item') )
                    });
                }
            }
        });
    }

    return {

        init: init,
        setNotificationRead: setNotificationRead

    }

}(jQuery));