var uiHelper = (function($) {

	'use strict';

	var submenuItem = $('.js-submenu-trigger'),
		navigationToggle = $('.js-navigation-toggle'),
		preventDefaultItems = $('.js-prevent-default'),
		mainWrapper = $('#js-main'),
		sidenavWrapper = $('#js-sidenav'),
		logo = $('.toolbar__logo'),
		langBtn = $('.js-set-form-lang-fields'),
		mainContainer = $('.main-container'),
		body = $('body'),
		translateBtn = $('#btn-translate');

	function _setCollapsetSidenav() {
		if(Cookies.get('neo-sidenav-collapse') === 'collapsed') {
			navigationToggle.addClass('active');
			mainWrapper.addClass('main--collapsed');
			sidenavWrapper.addClass('sidenav--collapsed');
		}
	}

	function neoCustomTab() {
		// Tab navigation

		$('.mdl-custom-tabs__tab').on('click', function() {
			var panels = $(this).parents('.mdl-content-header').next('.mdl-content').find('.mdl-custom-tabs__panel'),
				_self = $(this);
			
			$(this).siblings('.mdl-custom-tabs__tab').removeClass('is-active');
			$(this).addClass('is-active');

			panels.removeClass('is-active');

			panels.each(function() {
				if($(this).attr('data-id') === _self.attr('data-id')) {
					$(this).addClass('is-active');
				}
			});

		});
	}

	// Translate BTN
	
	mainContainer.on('click', '#btn-translate', function() {
		$(this).parents('.page-header__lang-wrapper').toggleClass('is-open');
	});

	// Copy to clipboard

	body.on('click', '.js-copy-input-to-clipboard', function(e) {
		e.preventDefault();

		var copyText = document.getElementById($(this).attr('data-input-id'));
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");

		swal({
			title: "Copied!",
			type: "success",
			timer: 800
		});
	});

	// Close magnific popups

	body.on('click', '.mdl-content-header__close-btn', function() {
		console.log('magnific close');
		$.magnificPopup.close();
	});


	// Error message handler

	function msgHandler() {
		if(mainContainer.find('.alert--success').length > 0) {
			setTimeout(function() {
				mainContainer.find('.alert--success').remove();
			}, 10000);
		}
	}

	mainContainer.on('click', '.alert__close', function() {
		$(this).parents('.alert').remove();
	});

    // Menu element search
	
	function menuElementSearchInit() {
		var searchBtn = $('.js-toolbar-search-btn'),
			serachField = $('.toolbar__search-field'),
			searchClose = $('.toolbar__search-field .icon__close'),
			searchInput = $('.toolbar__search-field-input'),
			searchResults = $('.toolbar__results'),
			searchResultsInner = $('.toolbar__results-inner'),
			timeoutKeypress = 0,
			documentNode = $(document);

		function _elementHandler(searchValue) {
			var re = new RegExp(searchValue, 'i'),
				items = sidenavWrapper.find('.navigation-button').not('[href="#"]').find('.navigation-button__title'),
				ids = [];

			searchResultsInner.empty();

			items.each(function() {
				if($(this).text().match(re)) {

					_setSearchItem($(this).parents('.navigation-button'));

				}
			});
		}

		function _setSearchItem(item) {

			var cloneItem = item.clone(),
				appendText = $('<span></span>'),
				menuItemText = '',
				icon = '';

			cloneItem.removeClass('navigation-button');
			cloneItem.find('.material-icons').remove();
			cloneItem.find('.icon').remove();
			cloneItem.find('.navigation-button__arrow').remove();

			if(item.parents('.submenu').length > 0) {

				var parentItems = item.parents('.submenu');

				$(parentItems.get().reverse()).each(function() {
					menuItemText += $(this).prev('.navigation-button').find('.navigation-button__title').text() + ' / ';

					if($(this).prev('.navigation-button').find('.navigation-button__title').prev('.icon').length > 0) {
						icon = $(this).prev('.navigation-button').find('.navigation-button__title').prev('.icon').clone();
					}
				});

				menuItemText += item.find('.navigation-button__title').text();

			} else {
				menuItemText += item.find('.navigation-button__title').text();
				if(item.find('.icon').length > 0) {
					icon = item.find('.icon').clone();
				}
			}

			appendText.append(icon);
			appendText.append(menuItemText);

			cloneItem.find('.navigation-button__title').html(appendText);

			searchResultsInner.append(cloneItem);
		}

		searchBtn.on('click', function() {
			serachField.toggleClass('is-visible');
			serachField.find('input').focus();
		});

		searchClose.on('click', function() {
			serachField.toggleClass('is-visible');
			serachField.find('input').blur();

			searchInput.val('');
			searchResultsInner.empty();
		});

		searchResultsInner.on('click', 'a', function() {
			serachField.toggleClass('is-visible');
			serachField.find('input').blur();

			searchInput.val('');
			searchResultsInner.empty();
		});

		searchInput.on('keyup', function (event) {
			
			if (timeoutKeypress !== undefined) {
	            clearTimeout(timeoutKeypress);
	        }
	        timeoutKeypress = setTimeout(function () {

	        	if(searchInput.val().length > 2) {
 					_elementHandler(searchInput.val());
	        	} else {
	        		searchResultsInner.empty();
	        	}

	        }, 500);
		});
	}

	navigationToggle.on('click', function() {
		$(this).toggleClass('active');

		mainWrapper.toggleClass('main--collapsed');

		sidenavWrapper.toggleClass('sidenav--collapsed');

		logo.toggleClass('toolbar__logo--square');

		if($(this).hasClass('active')) {
			Cookies.set('neo-sidenav-collapse', 'collapsed', { expires: 365 });
		} else {
			Cookies.set('neo-sidenav-collapse', 'extended', { expires: 365 });
		}
		setTimeout(function() {
			dashboardChartsHelper.update();
			
		}, 350);
	});

	submenuItem.on('click', function(e) {
		$(this).closest('.has-submenu').toggleClass('open');
		$(this).siblings('.submenu').toggleClass('open');

		if($(this).attr('data-submenu') === 'true' && !$(e.target).hasClass('navigation-button__arrow')) {
			$(this).closest('.has-submenu').addClass('open');
			$(this).siblings('.submenu').addClass('open');
		}

		setTimeout(function() {
			pfScrollbar.sidenavInit();
		}, 500);
		
	});

	$('body').on('click', '.js-set-form-lang-fields', function() {
		body.removeClass('lang-ru lang-en lang-de');
		body.addClass('lang-' + $(this).attr('data-lang'));
	});

	preventDefaultItems.on('click', function(e) {
		e.preventDefault();
	});

	_setCollapsetSidenav();

	// Dragula inicializálása

	function dragulaInit() {

		// Dashboard

		dragula([document.querySelector('#dragula-container1')],{
			direction: 'horizontal',
			moves: function (el, container, handle) {
				return handle.classList.contains('dg-handle');
			}
		}).on("drop", function(el, container, source) {
			saveDgOrdering(container, source);
		});

		dragula([document.querySelector('#dragula-container2'), document.querySelector('#dragula-container3')],{
			moves: function (el, container, handle) {
				return handle.classList.contains('dg-handle');
			}
		}).on("drop", function(el, container, source) {
			saveDgOrdering(container, source);
		});

		// Generic one column

		dragula([document.querySelector('#dragula-container')],{
			moves: function (el, container, handle) {
				return handle.classList.contains('dg-handle--header');
			}
		}).on("drag", function(el, source) {
			$('.js-block-item').addClass('dg-active');
		}).on("dragend", function(el, source) {
			$('.js-block-item').removeClass('dg-active');
		}).on("drop", function(el, container, source) {
			saveDgOrdering(container, source);
			saveBlockSettings();
		});

		// Gallery
		var formDataContainers = document.querySelectorAll('.gallery-grid');

		[].forEach.call(formDataContainers, function(item) {
			dragula([item],{
				moves: function (el, container, handle) {
					return handle.classList.contains('gallery-grid-item__dg-handle');
				}
			}).on("drop", function(el, container, source) {
				// saveDgOrdering(container, source);
				// saveBlockSettings();
				saveGalleryDgOrdering(container, source);
			});
		});
		
		// Generic row content

		var formDataContainers = document.querySelectorAll('.form-data-container');

		[].forEach.call(formDataContainers, function(item) {
			dragula([item],{
				moves: function (el, container, handle) {
					return handle.classList.contains('drag-and-drop-column__icon-wrapper');
				},
				accepts: function (el, target, source, sibling) {
					return sibling === null || !$(sibling).is('.dg-disabled');
				}
			}).on("drag", function(el, source) {
				$('.js-block-item').addClass('dg-active');
			}).on("dragend", function(el, source) {
				$('.js-block-item').removeClass('dg-active');
			}).on("drop", function(el, container, source) {
				saveDgOrdering(container, source);
				saveDgOrderingList(container);
			})
			;
		});
	}

	function saveDgOrderingList(container) {
		$(container).find('.form-data-row').not('.js-form-data-row-reference').each(function(index, value) {
			$(this).find('.order-field').val(parseInt(index) + 1);
		});
	}

	function saveBlockSettings() {
		var container = $('#dragula-container'),
			blocks = container.find('.mdl-block-item'),
			blockData = [],
			inputField = $('.block-position');

		blocks.each(function(index, value) {
			var closed = false;

			if($(this).hasClass('is-close')) {
				closed = true;
			}

			blockData.push({
				'name': $(this).attr('data-container'),
				'pos': index,
				'closed': closed
			});
		});

		inputField.val(JSON.stringify(blockData));
	}

	function saveGalleryDgOrdering(container, source){
		var ordering = [],
			sourceId = $(source).attr('data-id');

		$(container).find('.dg-item').each(function() {
			ordering.push($(this).attr('data-image-id'));
		});

		console.log(ordering);

		// ordering = JSON.stringify(ordering);

		// Cookies.set(containerItem, ordering);
	}

	function saveDgOrdering(container, source){
		var containerIds = [],
			containerId = $(container).attr('data-id'),
			sourceId = $(source).attr('data-id');

		containerIds.push(containerId);

		if (sourceId != containerId) {
			containerIds.push(sourceId);
		}

		$(containerIds).each(function(i, containerItem){
			var ordering = [];
			$('[data-id="' + containerItem + '"] .dg-item').each(function(j, dgItem){
				ordering.push($(dgItem).attr('data-id'));
			});

			ordering = JSON.stringify(ordering);

			Cookies.set(containerItem, ordering);
		});
	}

	// Live block search
	
	function liveSearchInit() {
		var searchWrapper = $('.live-block-search'),
			inputField = $('.live-block-search__input'),
			timeoutKeypress = 0,
			documentNode = $(document);

		function _blockHandler(searchValue) {
			var re = new RegExp(searchValue, 'i'),
				blocks = $('.js-block-item '),
				ids = [];

			blocks.each(function() {
				if($(this).find('.mdl-content-header__title').text().match(re)) {
					ids.push($(this).attr('data-container'));
				}
			});

			_blockSwitcher(ids);
		}

		function _blockSwitcher(ids) {
			$('.js-block-item ').hide();
			ids.forEach(function(item) {
				$('.js-block-item[data-container="' + item + '"]').show();
			});
		}

		inputField.on('keyup', function (event) {
	        if (timeoutKeypress !== undefined) {
	            clearTimeout(timeoutKeypress);
	        }
	        timeoutKeypress = setTimeout(function () {

				_blockHandler(inputField.val().trim());

	        }, 500);
	    });
	}

	// Block collapse

	function blockCollapseInit() {
		var toggle = $('.mdl-block-item .mdl-content-header__arrow'),
			items = $('.mdl-block-item');

		items.each(function() {
			if($(this).hasClass('is-close')) {
				$(this).find('.mdl-content').css({
					'height': 0
				});
			}
		});

		toggle.on('click', function() {

			var blockItem = $(this).parents('.mdl-block-item'),
				blockContentHeight = $(this).parents('.mdl-block-item').find('.mdl-content__inside').height();

			blockItem.toggleClass('is-close');

			if(blockItem.hasClass('is-close')) {
				blockItem.find('.mdl-content').animate({
					height: 0
				}, 300);
			} else {
				blockItem.find('.mdl-content').animate({
					height: blockContentHeight
				}, 300);
			}

			saveBlockSettings();
		});
	}

	// Block settings modules

    function blockSettingsInit() {

        $('.js-form-data-container').off('click.formDataDeleteItem');
        $('.js-form-data-container').on('click.formDataDeleteItem', '.delete-btn', function(e) {
            $(this).parents('.form-data-row').remove();

            $('.mdl-block-item[data-container="' + $(this).parents('.form-data-row').attr('data-container') + '"]').find('.mdl-content').css({
                'height': 'auto'
            });

            saveDgOrderingList($('.mdl-block-item[data-container="' + $(this).parents('.form-data-row').attr('data-container') + '"]'));
        });

		$('body').off('click.formDataAddItemQuickAction');
		$('body').on('click.formDataAddItemQuickAction', '.js-form-data-add-item-quickaction', function(e) {
			$('.js-form-data-add-item').click();
		});

        $('body').off('click.formDataAddItem');
        $('body').on('click.formDataAddItem', '.js-form-data-add-item', function(e) {

            $(this).parents('.mdl-content').css({
                'height': 'auto'
            });

            var item = $(this).parents('.form-data-action-row').siblings('.js-form-data-container').find('.js-form-data-row-reference').clone();

            console.log('Item:', item);

            $(this).parents('.form-data-action-row').siblings('.js-form-data-container').addClass('has-item');

            // Text input
            item.find('input[data-disabled="false"]').removeAttr('disabled');
            item.find('input[data-disabled="false"]').parents('.mdl-textfield').removeClass('is-disabled').removeClass('is-upgraded').removeAttr('data-upgraded');

            // Radio
            item.find('input[type="radio"][data-disabled="false"]').removeAttr('disabled');
            item.find('input[type="radio"][data-disabled="false"]').parents('label').removeClass('is-disabled').removeClass('is-upgraded').removeAttr('data-upgraded');
            item.find('input[type="radio"][data-disabled="false"]').parents('label').find('span:not(.mdl-radio__label)').remove();

            // Checkbox
            item.find('.mdl-checkbox').removeClass('is-upgraded').removeAttr('data-upgraded');

            // Textarea
            item.find('textarea[data-disabled="false"]').removeAttr('disabled');
            item.find('.fr-box ').remove();

            item.find('select').removeAttr('disabled');

            item.find('.sp-replacer').remove();

            item.removeClass('js-form-data-row-reference').removeClass('hidden');

			let newBlockContainer = null;
			if ( $(this).data('position') == 'first' ) {
	            item.insertBefore($(this).parents('.form-data-action-row').siblings('.js-form-data-container').find('.form-data-row:not(.js-form-data-row-reference)').first());
	            newBlockContainer = $(this).parents('.form-data-action-row').siblings('.js-form-data-container').find('.form-data-row:not(.js-form-data-row-reference)').first();
			}  else {
	            item.insertBefore($(this).parents('.form-data-action-row').siblings('.js-form-data-container').find('.display-helper'));
	            newBlockContainer = $(this).parents('.form-data-action-row').siblings('.js-form-data-container').find('.display-helper').prev();
			}

            // Froala init
            froalaEditor.reInitCustomFroalaEditorFull(newBlockContainer.find('textarea'));

            // Kulcs csere - ahol kell
            var randomKey = Math.random().toString(36).substring(7);
            newBlockContainer.find("input, select").each(function(i) {
                $(this).attr('name', $(this).attr('name').replace(/#RANDOMKEY#/g, randomKey));

                if ( $(this).attr('id') ) {
                    $(this).attr('id', $(this).attr('id').replace(/#RANDOMKEY#/g, randomKey));
                }
            });
            newBlockContainer.find("label").each(function(i) {
                if ( $(this).attr('for') ) {
                    $(this).attr('for', $(this).attr('for').replace(/#RANDOMKEY#/g, randomKey));
                }
            });
			newBlockContainer.find("a").each(function(i) {
				if ( $(this).attr('href') ) {
					$(this).attr('href', $(this).attr('href').replace(/#RANDOMKEY#/g, randomKey));
				}
			});

            saveDgOrderingList($(this).parents('.form-data-action-row').siblings('.js-form-data-container'));

            // Első input-ra focus állítás
            newBlockContainer.find('input:text').first().focus();

            window['colorPickerGlobalInit']();
            window['perfectScrollbarGlobalInit'](false);
            window['mdlGlobalInit']();
            postfixHelper.init();
            datepickerHelper.init();
            select2.settingsBlockInit();
            productModuleHelper.initSales();
			magnific.init();
        });
    }

	function matchHeightInit(){
	
		var mhInitItems = [];

		if ( $('[data-mh]').length ) {

			$('[data-mh]').each(function(i, el) {
				if($.inArray($(this).attr('data-mh'), mhInitItems) === -1) mhInitItems.push($(this).attr('data-mh'));
			});

			for(var i = 0; i < mhInitItems.length ; i++) {
				$('[data-mh="' + mhInitItems[i] + '"]').matchHeight({
				    byRow: true,
				    property: 'height',
				    target: null,
				    remove: false
				});
			}

		}
	}

	return {
		dragulaInit: dragulaInit,
		liveSearchInit: liveSearchInit,
		blockCollapseInit: blockCollapseInit,
		blockSettingsInit: blockSettingsInit,
		saveBlockSettings: saveBlockSettings,
		menuElementSearchInit: menuElementSearchInit,
		msgHandler: msgHandler,
		matchHeightInit: matchHeightInit,
		neoCustomTab: neoCustomTab,
		saveDgOrderingList: saveDgOrderingList
	}

}(jQuery));
