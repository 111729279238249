
/**
 * Infobox-okat kezelő script.
 */

var infoBox = (function($) {

    'use strict';


    var defaults = {},
        infoBoxRefresh = $('.js-infobox-refresh');

    /**
     * Box tartalmának frissítése
     * @param  {string} id [Box azonosító]
     */
    function _refreshBox(id) {
        var config          = $('#js-infobox-' + id).data('config');
        var interval        = $('#js-infobox-' + id+' .js-infobox-interval-value').val();
        var custom_interval = $('#js-infobox-' + id+' .daterange-from-to').val();

        $.ajax({
            url: GLOBAL_URIS.infobox_refresh + '/' + id,
            data: {
                'config': config,
                'interval': interval,
                'custom_interval': custom_interval
            },
            type: 'GET',
            datatype: 'json',
            beforeSend: function( xhr ) {
                $('#js-infobox-' + id).find('.dashboard__counter-box').addClass('is-loading');

                $('#js-infobox-' + id).find('.mdl-spinner').addClass('is-active');
            },
            success: function(data, textStatus, xhr) {
                setTimeout(function() {

                    $('#js-infobox-' + id).replaceWith(data[id]);

                    ajaxHelper.loadDependencies({
                        base: 'list',
                        additions: ['infobox'],
                        excepts: ['perfectScrollbar' ,'idle']
                    });

                    datepickerHelper.uniqueDaterangepickerReinit($('#js-infobox-' + id));

                }, 1000);               

            },
            error: function() {
            }
        });

    }

    function init() {

        /**
         * Box frissítése
         */
        $('.js-infobox-wrapper').off('click.js-infobox-refresh'); 
        $('.js-infobox-wrapper').on('click.js-infobox-refresh', '.js-infobox-refresh', function(e) {
            _refreshBox($(this).data('id'));
        });

        $('.js-infobox-wrapper').off('click.js-infobox-interval-menu'); 
        $('.js-infobox-wrapper').on('click.js-infobox-interval-menu', '.js-infobox-interval-menu .mdl-menu__item', function(e) {
            $(this).parents('.dashboard__counter-box').find('input.js-infobox-interval-value').val($(this).data('interval'));
            $(this).parents('.dashboard__counter-box').find('.interval-title').text($(this).find('.mdl-menu__interval-title').text());

            $(this).parents('.dashboard__counter-box').find('.daterange-from-to').val('');

            // Call refresh
            _refreshBox($(this).parents('.dashboard__counter-box').data('dropdown-id'));
        });

        $('.js-infobox-wrapper').off('apply.daterangepicker'); 
        $('.js-infobox-wrapper').on('apply.daterangepicker', '.daterange-from-to', function(ev, picker) {

            $(this).parents('.dashboard__counter-box').find('.interval-title').text($(this).val());
            $(this).parents('.dashboard__counter-box').find('.js-infobox-interval-value').val('');

            // Call refresh
            _refreshBox($(this).parents('.dashboard__counter-box').data('dropdown-id'));
        });
    }

    return {

        init: init

    }

}(jQuery));
