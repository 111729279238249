var szilardHelper = (function($) {

    function c3Init() {
        console.log('c3 func');
    }

    function perfectScrollbarBlockInit() {
        var psContainer = document.querySelectorAll('.js-ps-container');
        
        // [].forEach.call(psContainer, function(el, index) {
        //     Ps.destroy(el);
        //     Ps.initialize(el, {
        //       wheelSpeed: 0.5,
        //       wheelPropagation: false,
        //       //minScrollbarLength: 20
        //     }); 
        // });
    }

    function dateRangePickerInit(){
        $('.daterangepicker').daterangepicker();
    }

    return {
        c3Init: c3Init,
        perfectScrollbarBlockInit: perfectScrollbarBlockInit,
        dateRangePickerInit: dateRangePickerInit
    }
	
}(jQuery));


var dashboardChartsHelper = (function($) {

    var _options = {
        TAB_CLASS: '.js-update-overview-charts',
        ACTIVE_CLASS: 'is-active',
        DEFAULT_INTERVAL: ''
    };
    var _charts = {
        MAINCHART: '#chart-main'
    };
    var _interval = 'yesterday';

    function init(){
        initTabs();
        initCharts();
    }

    function update(){
        updateTabs();
        initCharts();
    }

//TABS
    function initTabs(){ 
        $(_options.TAB_CLASS).click(function(){
            _interval = $(this).data('interval');
            update(); 
        });
    }

    function updateTabs(){
        $(_options.TAB_CLASS).removeClass(_options.ACTIVE_CLASS);
        $(_options.TAB_CLASS + '[data-interval="' + _interval + '"]').addClass(_options.ACTIVE_CLASS);
    }

//CHARTS
    function initCharts(){
        for (var key in _charts) {
            $(_charts[key]).highcharts(getChartOptions(_charts[key]));
        }
    }



    function getChartOptions(chartId){
        switch(chartId) {
            case _charts.MAINCHART:
                return getMainChartOptions();
                break;
            
        }
    }

    function getMainChartOptions(){   
        return {
            chart: {
                type: 'area'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            colors: ['#2196f3'],
            tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x:%b %e.}: {point.y}'
            },
            plotoptions: {
                area: {
                    lineWidth: 0

                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%b %e.',
                    year: '%b'
                },
                title: {
                    text: ''
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                min: 0
            },
            series: [
                {
                    name: 'Visitors',
                    data: [
                        [Date.UTC(2016, 9, 21), 2345],
                        [Date.UTC(2016, 10, 4), 2724],
                        [Date.UTC(2016, 10, 9), 7245],
                        [Date.UTC(2016, 10, 27), 1346],
                        [Date.UTC(2016, 11, 2), 5134],
                        [Date.UTC(2016, 11, 26), 7134],
                        [Date.UTC(2016, 11, 29), 5134],
                        [Date.UTC(2017, 0, 11), 8724],
                        [Date.UTC(2017, 0, 26), 3756],
                        [Date.UTC(2017, 1, 3), 2473],
                        [Date.UTC(2017, 1, 11), 2457],
                        [Date.UTC(2017, 1, 25), 2345],
                        [Date.UTC(2017, 2, 11), 8255],
                        [Date.UTC(2017, 3, 11), 2344],
                        [Date.UTC(2017, 4, 1), 5243],
                        [Date.UTC(2017, 4, 5), 7245],
                        [Date.UTC(2017, 4, 19), 2345],
                        [Date.UTC(2017, 5, 3), 2675]
                    ]
                }
            ]
        }
    }

    return {
       
        init: init,
        update: update
        
    }
    
}(jQuery));