var dashboardModuleHelper = (function($) {

	'use strict';

	function init() {

        // Overview
        $('body').off('click.js-dashboard-overview-interval');        
        $('body').on('click.js-dashboard-overview-interval', '.js-dashboard-overview-interval', function (){
            $('.js-dashboard-overview-interval').removeClass('active');
            $(this).addClass('active');
            $('#js-dashboard-overview-wrapper').addClass('is-loading');

            $('input[name="overview_interval"]').val( $(this).attr('data-interval') );
            _getOverviewContent();
            return false;
        });
        _getOverviewContent();

        // Top5
        $('body').off('click.js-dashboard-top5-interval');        
        $('body').on('click.js-dashboard-top5-interval', '.js-dashboard-top5-interval', function (){
            $('.js-dashboard-top5-interval').removeClass('active');
            $(this).addClass('active');
            $('#js-dashboard-top5-wrapper').addClass('is-loading');

            $('input[name="top5_interval"]').val( $(this).attr('data-interval') );
            _getTop5Content();
            return false;
        });
        _getTop5Content();

    }

    /**
     * Overview frissítése
     */
    function _getOverviewContent() {  
        var _interval = $('input[name="overview_interval"]').val();

        $.ajax({
            url: $('#js-dashboard-overview').attr('data-url'),
            type: 'POST',
            data: {
                interval: _interval
            },
            success: function(data, textStatus, xhr) {
                $('#js-dashboard-overview-wrapper').removeClass('is-loading');
                $('.js-dashboard-overview-table-empty-msg').hide();
                $('#js-dashboard-overview').html(data);
            },
            error: function(xhr, status, error) {
                var response = $.parseJSON(xhr.responseText);
                sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
            }
        });       
    }

    /**
     * Top5 frissítése
     */
    function _getTop5Content() {  
        var _interval = $('input[name="top5_interval"]').val();

        $.ajax({
            url: $('#js-dashboard-top5').attr('data-url'),
            type: 'POST',
            data: {
                interval: _interval
            },
            success: function(data, textStatus, xhr) {
                $('#js-dashboard-top5-wrapper').removeClass('is-loading');
                $('.js-dashboard-top5-table-empty-msg').hide();
                $('#js-dashboard-top5').html(data);
            },
            error: function(xhr, status, error) {
                var response = $.parseJSON(xhr.responseText);
                sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
            }
        });       
    }

    return {

        init: init,

	}

}(jQuery));