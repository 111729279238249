var appearanceModuleHelper = (function($) {

	'use strict';

    function loadGridBlockItems(e, selected) {
        $.ajax({
            url: e.attr('data-items-url'),
            type: 'POST',
            data:  {
                'grid_combo': e.val(),
                'selected': selected,
                'page_id': e.attr('data-page-id')
            },
            beforeSend:function(jqXHR, settings){
                //$('.toolbar .mdl-spinner').addClass('is-active');
            },            
            success: function(data, textStatus, jqXHR) {  
                var target_select = e.closest('.form-data-row').find('.js-appearance-pages-mainpage-items');

                target_select.find("optgroup").remove();
                //target_select.find("option:gt(0)").remove();

                $.each(data.merged_items, function(group_title, group_items) {
                    var optgroup = $('<optgroup>');
                    optgroup.attr('label', group_title);

                    $.each(group_items, function(index, val) {
                        var option = $('<option>', {
                            value: val.id,
                            text: val.title
                        }).attr('data-image', val.image).attr('data-type', val.type);

                        if ( typeof selected !== 'undefined' && selected.length > 0 && $.inArray(val.id, selected) >= 0 ) {
                            option = option.prop('selected', true);
                        }

                        if ( val.inactive == 1 ) {
                            option = option.attr('data-type', val.type + '-inactive');
                        }

                        optgroup.append(option);
                    });

                    target_select.append(optgroup);
                });

                if ( e.val() == 12 ) {
                    e.closest('.form-data-row').find('.js-grid-row-narrow-container').show();
                } else {
                    e.closest('.form-data-row').find('.js-grid-row-narrow-container').hide();
                }

                target_select.select2({     
                    maximumSelectionLength: data.max_items_num,
                    minimumResultsForSearch: -1,
                    placeholder: "Search...",
                    closeOnSelect: true,
                    templateSelection: select2.relatedProductStateBadgeSelection,
                    templateResult: select2.relatedProductStateBadge
                });

                target_select.on('select2:unselecting', function (e) {
                    setTimeout(function() {
                        reloadSelectOrder(selected, target_select.next('.select2').find('.ui-sortable'));
                        updateSelectOrder(target_select.next('.select2').find('.ui-sortable'));
                    }, 0);
                });

                target_select.parent().find("ul.select2-selection__rendered").sortable({
                    forcePlaceholderSize: true,
                    tolerance: 'pointer',
                    items: 'li:not(.select2-search--inline)',
                    update: function() {
                        updateSelectOrder($(this));
                    }
                });

                reloadSelectOrder(selected, target_select.next('.select2').find('.ui-sortable'));

                updateSelectOrder(target_select.next('.select2').find('.ui-sortable'));
            }        
        });
    }

    function reloadSelectOrder(selected, list) {
        $.each(selected, function(index, val) {
            var tempItem = '';

            tempItem = list.find('li[data-id="' + val + '"]');

            list.find('li[data-id="' + val + '"]').insertAfter(list.find('.select2-selection__choice').last()); 
        });
    }

    function updateSelectOrder(self) {
        var order = [];

        $.each(self.find('li.select2-selection__choice'), function(index, val) {
            order.push($(this).attr('data-id'));
        });

        self.parents('.mdl-textfield').find('.js-input-order-helper').val(order.join());
    }

	function init() {

        /**
         *  Settings / Shop / Grid combo változtatás
         */
        $('.js-ajax-post-form').on('change', '.js-appearance-pages-mainpage-grid', function (){
            var e = $(this);

            loadGridBlockItems(e);
        });

    }

    return {

        init: init,
		loadGridBlockItems: loadGridBlockItems,

	}

}(jQuery));