
/**
 * Tétlenséget ellenörző függvény. Ha a felhasználó 30 percig tétlen (nem használja az egeret,
 * vagy nem üt le billentyűt az adott oldalon), akkor lezárjuk a képernyőjét.
 */
var idleHelper = (function($) {

	var idleTime = 0,
		timeout = 0,
		timeoutKeypress = 0,
		idleInterval = 0,
		documentNode = $(document),
		debug = false;

		var countdownTimeout = null;
		var countdownTime = null;

	function _timerIncrement() {
	    idleTime = idleTime + 1;

	    if (debug) console.debug('Current Idle time:', idleTime, '--> RESET <--');

	    if (idleTime > (GLOBAL_SETTINGS.authentication_lock_time - 1)) { // 30 minutes
	    	clearInterval(idleInterval);
	    	idleTime = 0;

	    	if (debug) console.debug('Clear Interval and reset settings...');

	    	documentNode.off('mousemoveend');
	    	documentNode.off('keypressend');
	        _setLock();
	    }
	}

	function _setLock() {
	    $.ajax({
	        url: '/lockscreen',
	        success: function(data, textStatus, xhr) {	       
	        	$('#js-lock-screen').html(data);

	        },
	        error: function() {
				//sweetAlert("Oops...", "Something went wrong!", "error");
	        }
	    });
	}

	function init() {

		if ( GLOBAL_SETTINGS.authentication_lock_disable == 1 ) {
			return;
		}

		//Increment the idle time counter every minute.
	    idleInterval = setInterval(_timerIncrement, 60000); // 1 minute

	    // Zero the idle timer on mouse movement.
	    documentNode.on('mousemoveend', function (e) {
	    	if (debug) console.debug('Current Idle time:', idleTime, '--> RESET <--');
        	idleTime = 0;
	    });

	    // Zero the idle timer on key press.
	    documentNode.on('keypressend', function (e) {
	    	if (debug) console.debug('Current Idle time:', idleTime, '--> RESET <--');
        	idleTime = 0;
	    });
	}

    documentNode.on('mousemove', function (event) {
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(function () {
            $(event.target).trigger('mousemoveend');
        }, 100);
    });

    documentNode.on('keypress', function (event) {
        if (timeoutKeypress !== undefined) {
            clearTimeout(timeoutKeypress);
        }
        timeoutKeypress = setTimeout(function () {
            $(event.target).trigger('keypressend');
        }, 1000);
    });

	documentNode.on('resetidlecounter', function (e) {
		clearInterval(idleInterval);
	    idleTime = 0;
	});

	/**
	 * Lock screen indítása visszaszámlálóval
	 * @param  {object}    [Esemény objektum]
	 */
	documentNode.on('click', '.js-user-set-lock', function(e) {
		e.preventDefault();

		startCountdown(11, 1000);

		swal({
			title: "Are you sure?",
			text: "Do you want to lock your account?",
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#F44336",
			confirmButtonText: "Yes, do it!",
			closeOnConfirm: false,
			timer: 11000
		},
		function(){
			swal.close();
		 	_setLock();
		});
    });

	function startCountdown(timen, pause) {
	    countdownTime = timen;
	    $('.sa-button-container .confirm').html('Yes, do it! (' + timen + ')');
	    if(timen == 0) {
	    } else {
	        clearTimeout(countdownTimeout);
	        countdownTimeout = setTimeout(function(){
	            startCountdown(timen-1, pause)
	        }, pause);
	    }
	}

    return {
		init: init
	}

}(jQuery));





/**
 * Tétlenség során betöltött "Lock screen" betöltésért felelős kódrészlet.
 */
var lockScreenHelper = (function($) {

	'use strict';

	function init() {

	    $('body').on('submit', '.js-ajax-post-form-lockscreen', function(e) {
	        e.preventDefault();

	        var formObj = $(this),
	        	formURL = formObj.attr("action"),
	        	formData = new FormData(formObj[0]);

			$.ajax({
	            url: formURL,
	            type: 'POST',
	            data:  formData,
	            datatype: 'json',
	            cache: false,
	            contentType: false,
	            processData: false,          
	            success: function(data, textStatus, jqXHR) {     
	            	if ( data.msg == 'ok' ) {
	            		if ( $('#js-lock-screen').is(':empty') ){
	            			window.location.href = '/dashboard';
	            		} else {
	            			$('#js-lock-screen').html('');
	            			clearInterval(lockIdleHelper);
	            			idleHelper.init();
	            		}
	            	} else {
	            		$("#lockscreen-error-message").addClass('is-invalid');
	            	}
	            }        
	        });
	    });

	}

	return {

		init: init

	}

}(jQuery));
