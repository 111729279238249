var translateHelper = (function($) {

	'use strict';

	function init() {

		var langBtns = $('.js-set-form-lang-fields');

		langBtns.on('click', function() {
			if($(this).attr('data-type') === 'block-translate') {
				if($(this).attr('data-lang') === 'en') {
					_reSetEnTranslatableBlocks();
				} else if($(this).attr('data-lang') !== 'en') {
					_setEnTranslatableBlocks();
				}
/*
				if(tinymce) {
					//window['tinyMceEnGlobalInit']();
					//
					tinyMce.destroyEn();

					setTimeout(function() {
						tinyMce.initEn();
					}, 150);
				}*/
/*
				if(tinymce) {
					setTimeout(function() {
						window['tinyMceGlobalInit']();
					}, 300);
				}*/
			}
		});

	    _setTranslatableBlocks();

	}


	function _reSetEnTranslatableBlocks() {
		var helper = $('.translate-wrapper'),
			blocksToMove = helper.find('.mdl-cell[data-lang="en"] .mdl-block-item');

		blocksToMove.each(function() {
			var _self = $(this),
				originalBlocks = _self.parents('.translate-wrapper').siblings('.translatable-area').find('.mdl-block-item');

			if(originalBlocks.length === 0) {
				_self.appendTo(_self.parents('.translate-wrapper').siblings('.translatable-area').find('.mdl-cell'));
			} else {
				originalBlocks.each(function() {

					if(Number($(this).attr('data-order')) === (Number(_self.attr('data-order')) - 1)) {
						_self.insertAfter($(this));
					} else {
						_self.appendTo(_self.parents('.translate-wrapper').siblings('.translatable-area').find('.mdl-cell[data-col="'+ _self.attr('data-col') +'"]'));
					}
				});
			}
		});
	}


	function _setEnTranslatableBlocks() {
		var blockItems = $('.translatable-area[data-translatable-wrapper="true"] .js-block-item');

		blockItems.each(function() {

			if($(this).attr('data-translatable') === 'true' && $(this).attr('data-lang') === 'en') {
				_blockMoverHelper($(this));
			}
		});
	}


	function _setTranslatableBlocks() {
		var blockItems = $('.translatable-area .js-block-item');

		blockItems.each(function() {
			if($(this).attr('data-translatable') === 'true' && $(this).attr('data-lang') !== 'en') {
				_blockMoverHelper($(this));
			}
		});
	}


	function _blockMoverHelper(_self) {
		var currentItem = '';

		_self.appendTo(_self.parents('.translatable-area').siblings('.translate-wrapper').find('.mdl-cell[data-lang="' + _self.attr('data-lang') + '"]'));
	}


	return {

		init: init

	}

}(jQuery));