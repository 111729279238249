
var froalaEditor = (function($) {

    'use strict';

    function init() {

        if (!$('.editor-simple').data('froala.editor')) {

            $.FroalaEditor.DefineIconTemplate('material_design', '<i class="material-icons">[NAME]</i>');

            $.FroalaEditor.ICON_DEFAULT_TEMPLATE = 'material_design';

            $.FroalaEditor.DefineIcon('bold', {NAME: 'format_bold'});
            $.FroalaEditor.DefineIcon('italic', {NAME: 'format_italic'});
            $.FroalaEditor.DefineIcon('underline', {NAME: 'format_underline'}); 
            $.FroalaEditor.DefineIcon('formatUL', {NAME: 'format_list_bulleted'});


            $.FroalaEditor.DefineIcon('linkOpen', {NAME: 'open_in_new'});
            $.FroalaEditor.DefineIcon('linkRemove', {NAME: 'remove'});


            $('.editor-simple').froalaEditor({
                toolbarBottom: true,
                toolbarButtons: ['formatUL', 'bold', 'italic', 'underline', 'insertLink'],
                linkEditButtons: ['linkEdit', 'linkRemove'],
                linkInsertButtons: [],

                //image
                imageUploadURL: GLOBAL_SETTINGS.api_url + '/froala-upload'                
            });
        }

        if (!$('.editor-full').data('froala.editor')) {
/*
            $.FroalaEditor.DefineIconTemplate('material_design', '<i class="material-icons">[NAME]</i>');

            $.FroalaEditor.ICON_DEFAULT_TEMPLATE = 'material_design';

            $.FroalaEditor.DefineIcon('bold', {NAME: 'format_bold'});
            $.FroalaEditor.DefineIcon('italic', {NAME: 'format_italic'});
            $.FroalaEditor.DefineIcon('underline', {NAME: 'format_underline'}); 
            $.FroalaEditor.DefineIcon('formatUL', {NAME: 'format_list_bulleted'});


            $.FroalaEditor.DefineIcon('linkOpen', {NAME: 'open_in_new'});
            $.FroalaEditor.DefineIcon('linkRemove', {NAME: 'remove'});*/


            // extended
            $.FroalaEditor.DefineIcon('undo', {NAME: 'undo'});
            $.FroalaEditor.DefineIcon('redo', {NAME: 'redo'});
            $.FroalaEditor.DefineIcon('quote', {NAME: 'format_quote'});
            $.FroalaEditor.DefineIcon('align', {NAME: 'format_align_left'});
            $.FroalaEditor.DefineIcon('align-left', {NAME: 'format_align_left'});
            $.FroalaEditor.DefineIcon('align-center', {NAME: 'format_align_center'});
            $.FroalaEditor.DefineIcon('align-right', {NAME: 'format_align_right'});
            $.FroalaEditor.DefineIcon('align-justify', {NAME: 'format_align_justify'});
            $.FroalaEditor.DefineIcon('fullscreen', {NAME: 'fullscreen'});
            $.FroalaEditor.DefineIcon('fullscreenCompress', {NAME: 'fullscreen_exit'});
            $.FroalaEditor.DefineIcon('paragraphFormat', {NAME: 'paragraph'});
            $.FroalaEditor.DefineIcon('insertImage', {NAME: 'crop_original'});
            $.FroalaEditor.DefineIcon('imageUpload', {NAME: 'file_upload'});
            $.FroalaEditor.DefineIcon('html', {NAME: 'code'});

            // table
            $.FroalaEditor.DefineIcon('insertTable', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableHeader', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableRemove', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableRows', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableColumns', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableStyle', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableCells', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableCellBackground', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableCellVerticalAlign', {NAME: 'border_all'});
            $.FroalaEditor.DefineIcon('tableCellVHorizontalAlign', {NAME: 'border_all'});

            $.FroalaEditor.DefineIcon('strikeThrough', {NAME: 'strikethrough_s'});
            $.FroalaEditor.DefineIcon('subscript', {NAME: 'vertical_align_bottom'});
            $.FroalaEditor.DefineIcon('superscript', {NAME: 'vertical_align_top'});

            $.FroalaEditor.DefineIcon('fontFamily', {NAME: 'font_download'});
            $.FroalaEditor.DefineIcon('fontSize', {NAME: 'format_size'});
            $.FroalaEditor.DefineIcon('formatUL', {NAME: 'link'});

            $.FroalaEditor.DefineIcon('color', {NAME: 'color_lens'});
            $.FroalaEditor.DefineIcon('inlineStyle', {NAME: 'style'});
            $.FroalaEditor.DefineIcon('paragraphStyle', {NAME: 'short_text'});

            $.FroalaEditor.DefineIcon('paragraphFormat', {NAME: 'line_style'});
            $.FroalaEditor.DefineIcon('formatOL', {NAME: 'format_list_numbered'});
            $.FroalaEditor.DefineIcon('formatUL', {NAME: 'format_list_bulleted'});

            $.FroalaEditor.DefineIcon('outdent', {NAME: 'format_indent_decrease'});
            $.FroalaEditor.DefineIcon('indent', {NAME: 'format_indent_increase'});

            // video
            $.FroalaEditor.DefineIcon('insertVideo', {NAME: 'video_library'});
            $.FroalaEditor.DefineIcon('videoRemove', {NAME: 'delete'});
            $.FroalaEditor.DefineIcon('videoSize', {NAME: 'zoom_out_map'});
            $.FroalaEditor.DefineIcon('videoBack', {NAME: 'arrow_back'});

            // Image edit
            $.FroalaEditor.DefineIcon('imageRemove', {NAME: 'delete'});
            $.FroalaEditor.DefineIcon('imageReplace', {NAME: 'cached'});
            $.FroalaEditor.DefineIcon('imageStyle', {NAME: 'style'});
            $.FroalaEditor.DefineIcon('imageSize', {NAME: 'photo_size_select_large'});
            $.FroalaEditor.DefineIcon('imageBack', {NAME: 'keyboard_backspace'});

            $.FroalaEditor.DefineIcon('insertHR', {NAME: 'remove'});
            $.FroalaEditor.DefineIcon('clearFormatting', {NAME: 'block'});
            $.FroalaEditor.DefineIcon('selectAll', {NAME: 'select_all'});

            // Define an icon
            $.FroalaEditor.DefineIcon('custom_buttons', {NAME: 'add_box'});

            $.FroalaEditor.RegisterCommand('custom_buttons', {
                title: 'Custom Buttons',
                type: 'dropdown',
                focus: false,
                undo: false,
                refreshAfterCallback: true,
                options: {
                    'v1': 'Bordered text button',
                    'v2': 'Magnific video'
                },
                callback: function (cmd, val) {

                    if(val == 'v1') {
                        this.html.insert('<a href="#" class="js-load-ajax-content text-button text-button--line">Custom Button</a>');
                    }

                    if(val == 'v2') {
                        this.html.insert('<a href="#" class="product-details__play-video play-video js-magnific-video-popup"></a>');
                    }
                    
                }
            });

            $.FroalaEditor.DefineIcon('read_more', {NAME: 'more_horiz'});

            $.FroalaEditor.RegisterCommand('read_more', {
                title: 'Read More',
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                    this.html.insert('<p class="read-more">Read More</p>');
                }
            });


            $('.editor-full').froalaEditor({
                toolbarBottom: true,
                // toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'quote', 'insertHR', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html'],
                toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', /*'inlineStyle',*/ '|', 'paragraphFormat', 'paragraphStyle', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'insertLink', 'insertImage', 'insertVideo', 'insertTable', '|', 'quote', 'insertHR', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'custom_buttons', 'read_more'],
                linkEditButtons: ['linkEdit', 'linkRemove'],
                linkInsertButtons: [],

                // Define new inline styles.
                inlineStyles: {
                    'Margin Top (0)': 'margin-top: 0;',
                    'Margin Top (10px)': 'margin-top: 10px;',
                    'Margin Top (20px)': 'margin-top: 20px;',
                    'Margin Bottom (0)': 'margin-bottom: 0;',
                    'Margin Bottom (10px)': 'margin-bottom: 10px;',
                    'Margin Bottom (20px)': 'margin-bottom: 20px;'
                },

                paragraphStyles: {
                    'banner-highlighted-title': 'Highlighted Title',
                    'mt0': 'Margin Top (0px)',
                    'mt10': 'Margin Top (10px)',
                    'mt20': 'Margin Top (20px)',
                    'mb0': 'Margin Bottom (0px)',
                    'mb10': 'Margin Bottom (10px)',
                    'mb20': 'Margin Bottom (20px)',
                },

                // CodeMirror
                codeMirror: true,
                codeMirrorOptions: {
                    tabSize: 4,
                    indentWithTabs: true,
                    lineNumbers: true,
                    lineWrapping: true,
                    mode: 'text/html',
                    tabMode: 'indent'
                },

                //image
                imageUploadURL: GLOBAL_SETTINGS.api_url + '/froala-upload'                
            });
        }
    }

    function reInitCustomFroalaEditorFull(element) {

        element.froalaEditor({
            toolbarBottom: true,
            // toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'quote', 'insertHR', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html'],
            toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', /*'inlineStyle',*/ '|', 'paragraphFormat', 'paragraphStyle', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'insertLink', 'insertImage', 'insertVideo', 'insertTable', '|', 'quote', 'insertHR', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'custom_buttons', 'read_more'],
            linkEditButtons: ['linkEdit', 'linkRemove'],
            linkInsertButtons: [],

            // Define new inline styles.
            inlineStyles: {
                'Margin Top (0)': 'margin-top: 0;',
                'Margin Top (10px)': 'margin-top: 10px;',
                'Margin Top (20px)': 'margin-top: 20px;',
                'Margin Bottom (0)': 'margin-bottom: 0;',
                'Margin Bottom (10px)': 'margin-bottom: 10px;',
                'Margin Bottom (20px)': 'margin-bottom: 20px;'
            },

            paragraphStyles: {
                'banner-highlighted-title': 'Highlighted Title',
                'mt0': 'Margin Top (0px)',
                'mt10': 'Margin Top (10px)',
                'mt20': 'Margin Top (20px)',
                'mb0': 'Margin Bottom (0px)',
                'mb10': 'Margin Bottom (10px)',
                'mb20': 'Margin Bottom (20px)',
            },

            // CodeMirror
            codeMirror: true,
            codeMirrorOptions: {
                tabSize: 4,
                indentWithTabs: true,
                lineNumbers: true,
                lineWrapping: true,
                mode: 'text/html',
                tabMode: 'indent'
            },

            //image
            imageUploadURL: GLOBAL_SETTINGS.api_url + '/froala-upload'
        });

    }

    return {

        init: init,
        reInitCustomFroalaEditorFull: reInitCustomFroalaEditorFull

    }

}(jQuery));