var userModuleHelper = (function($) {

	'use strict';

	function init() {

		var userAvatarInput = $("#user-avatar-input"),
			userAvatarImage = $('#user-avatar'),
			userAvatarUploadIcon = $('.page-header-user__avatar-icon'),
			userAvatarWrapper = $('.page-header-user__avatar-image-wrapper'),
			userAvatarRemove = $('.js-user-avatar-remove'),
			userAvatarRemoveInput = $('.js-user-avatar-remove-input'),
			userBackgroundInput = $("#user-background-input"),
			userBackgroundImage = $('.page-header--user-profile'),
			userEditIcon = $('.page-header-user__name-edit'),
			userName = $('.page-header-user__name'),
			nickInputField = $('input#nick'),
			activeCheckField = $('input#active'),
			disabledCheckField = $('input#disabled'),
			userSortOrders = $('.js-sort-table-user-orders');


		function _setCarretPosition() {
			var node = document.querySelector(".page-header-user__name > span"),
				textNode = node.firstChild,
				caret = textNode.length,
				range = document.createRange(),
			 	sel = window.getSelection();

			node.focus();
			
			range.setStart(textNode, caret);
			range.setEnd(textNode, caret);

			sel.removeAllRanges();
			sel.addRange(range);
		}


		/**
		 *	Felhasználói avatar feltöltésének, behelyettesítésének a lekezelése.
		 */
		userAvatarInput.on('change', function (){
	        var img = userAvatarImage;
	        var file = this.files[0];

	        var reader = new FileReader();
	        reader.onload = function (e) {
	            img.attr('src', e.target.result);
	            userAvatarUploadIcon.hide();
	        };
	        reader.readAsDataURL(file);

            userAvatarRemove.removeClass('hidden');
            userAvatarRemoveInput.val(0);

	        if(file.size/1024 > 500) {
	        	userAvatarWrapper.addClass('is-error')
	        } else {
	        	userAvatarWrapper.removeClass('is-error');
	        }
	    });


		/**
		 *	Fejlécben található felhazsnálói név szerkesztése.
		 */
		userEditIcon.on('click', function() {
			userName.find('span').attr('contenteditable', 'true')
			// userName.find('span').focus();

			_setCarretPosition();

			userName.find('span').keypress(function(e){ return e.which != 13; });
		});

	    userName.find('span').on('input', function() {

	    	nickInputField.parents('.mdl-textfield').addClass('is-focused');

	    	var updateString = String($(this).text().trim());

			setTimeout(function() {
				nickInputField.val(updateString);
			}, 400);
	    });


	    /**
		 *	Űrlapon található felhazsnálói név szerkesztése.
		 */
	    nickInputField.on('keyup', function() {
	    	var _self = $(this);
	    	setTimeout(function() {
	    		var updateString = String(_self.val().trim());
				userName.find('span').text(updateString);
			}, 400);	
	    });


	   	/**
		 *	Felhasználói háttér feltöltésének, behelyettesítésének a lekezelése.
		 */
		userBackgroundInput.on('change', function (){
	        var img = userBackgroundImage;
	        var file = this.files[0];

	        var reader = new FileReader();
	        reader.onload = function (e) {
	            img.css({
	            	'background-image': 'url(' + e.target.result + ')'
	            });
	        };
	        reader.readAsDataURL(file);
	    });

	   	/**
		 *	Ha a felhasználó saját magát akarja inaktiválni, figyelmeztetjük.
		 */
		activeCheckField.on('change', function (){
			var form_self = $(this).closest('form').attr('data-user-self-modify');

			if (typeof form_self !== typeof undefined && form_self !== false && $(this).is(':checked') === false) {
                setTimeout(function() {
                	sweetAlert(GLOBAL_MESSAGES.global_warning+'!', GLOBAL_MESSAGES.users_inactivation_own_account+'!', "error");
                }, 200);
			}
	    });

        /**
         *	Ha a felhasználó saját magát akarja inaktiválni, figyelmeztetjük.
         */
        disabledCheckField.on('change', function (){
            var form_self = $(this).closest('form').attr('data-user-self-modify');

            if (typeof form_self !== typeof undefined && form_self !== false && $(this).is(':checked') === true) {
                setTimeout(function() {
                    sweetAlert(GLOBAL_MESSAGES.global_warning+'!', GLOBAL_MESSAGES.users_disable_own_account+'!', "error");
                }, 200);
            }
        });

        /**
         *	Fejlécben található felhazsnálói kép törlése.
         */
        userAvatarRemove.on('click', function(e) {
        	e.preventDefault();

            var img = userAvatarImage;
            img.attr('src', '/app/assets/images/avatars/avatar.jpg');
            userAvatarUploadIcon.hide();

            userAvatarWrapper.removeClass('is-error');

            userAvatarRemove.addClass('hidden');
            userAvatarRemoveInput.val(1);

            userAvatarInput.val('');
        });

		/**
		 *	User adatlapon a rendelések rendezése
		 */
		userSortOrders.on('click', function() {
			var table_url = window.location.href;
			table_url = ajaxHelper.addUrlParameter(table_url, 'order_field_orders', $(this).attr('data-order-field'), false);
			table_url = ajaxHelper.addUrlParameter(table_url, 'order_type_orders', $(this).attr('data-order-type'), false);
			table_url = ajaxHelper.addUrlParameter(table_url, 'table_only_orders', 1, false);

			ajaxHelper.loadContent({
				_url: table_url,
				_force_redirect: '0',
				_url_title: 'preserve',
				_callback: '',
				_container: 'js-ordered-games-container',
				_push_state: 'false',
				_self: $(this)
			});

			return false;
		});

    }

    return {

		init: init

	}

}(jQuery));
