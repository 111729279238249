
/**
 * Adat táblázatok esetében a sorba rendezésért felelős kód (táblázat fejlécében található rendező gombok aktiválják). 
 */
var sortHelper = (function($) {

    'use strict';

    function init() {

        $('.js-sort-table').on('click', function() {
            var table_url = window.location.href;
            table_url = ajaxHelper.addUrlParameter(table_url, 'order_field', $(this).attr('data-order-field'), false);
            table_url = ajaxHelper.addUrlParameter(table_url, 'order_type', $(this).attr('data-order-type'), false);
            
            ajaxHelper.loadContent({
                _url: table_url,
                _force_redirect: '0',
                _url_title: 'preserve',
                _callback: '',
                _container: 'js-data-table',
                _push_state: 'true',
                _self: $(this)
            });
            
            return false;
        });

    }

    return {

        init: init

    }

}(jQuery));





/**
 * Adat táblázat lapozás megvalósítása.
 */

var paginationHelper = (function($) {

	'use strict';

	function init() {

		$('.js-table-perpage').on('change', function() {
			var table_url = ajaxHelper.addUrlParameter(window.location.href, 'perpage', $(this).val(), false);
			table_url = ajaxHelper.removeUrlParameter(table_url, 'page');

	        ajaxHelper.loadContent({
	            _url: table_url,
	            _force_redirect: '0',
	            _url_title: 'preserve',
	            _callback: '',
	            _container: 'js-data-table',
	            _push_state: 'true',
	            _self: $(this)
	        });
		});

	}

	return {

		init: init

	}

}(jQuery));





/**
 * Tablesaw implementálása
 */

var tablesawHelper = (function($) {

    'use strict';

    function init() {
        if($('.tablesaw').length > 0) {
            $( document ).trigger( "enhance.tablesaw" );
        }

        $('.mdl-checkbox__input').change(function() {
            setTimeout(function() {
                pfScrollbar.tableInit();
            }, 0);
        });
    }

    return {

        init: init

    }

}(jQuery));