var mediaModuleHelper = (function($) {

	'use strict';

	function init() {

        var productAvatarInput = $("#user-avatar-input"),
            productAvatarImage = $('#user-avatar'),
            productAvatarUploadIcon = $('.page-header-user__avatar-icon'),
            productAvatarWrapper = $('.page-header-user__avatar-image-wrapper'),
            productBackgroundInput = $("#user-background-input"),
            productBackgroundImage = $('.page-header--user-profile'),
            productMediaUploadToggle = $('.mdl-content-header__upload-btn'),
            productMediaImagesSelectAll = $('.js-media-product-images-select-all'),
            productMediaImagesDelete = $('.js-media-product-images-delete'),
            productMediaVideosTagSelect = $('.js-media-item-video-tag-selector'),
            productMediaImagesTagSelect = $('.js-media-item-image-tag-selector');

        // Header Selects
        $('.js-header-open-select').on('click', function() {
            $(this).parent('.page-header-generic__meta').find('select').select2('open');
        });

        $('.js-select2-simple--header').on('select2:select', function (e) {
            var data = $(this).select2('data');
            $(this).parents('.page-header-generic__meta').find('.page-header-generic__meta-text').text(data[0].text);

            $($(this).attr('data-connected-select')).val(data[0].id).trigger('change.select2');
        });

        $('.mdl-tabs__panel').on('select2:select', '.js-select2-simple--modify-header', function (e) {
            var data = $(this).select2('data');
            $($(this).attr('data-connected-select')).parents('.page-header-generic__meta').find('.page-header-generic__meta-text').text(data[0].text);

            $($(this).attr('data-connected-select')).val(data[0].id).trigger('change.select2');
        });

        /**
         *	Tétel avatar feltöltésének, behelyettesítésének a lekezelése.
         */
        productAvatarInput.on('change', function (){
            var img = productAvatarImage;
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function (e) {
                img.attr('src', e.target.result);
                productAvatarUploadIcon.hide();

            };
            reader.readAsDataURL(file);

            if(file.size/1024 > 500) {
                productAvatarWrapper.addClass('is-error')
            } else {
                productAvatarWrapper.removeClass('is-error');
            }
        });

        /**
         *	Tétel háttér feltöltésének, behelyettesítésének a lekezelése.
         */
        productBackgroundInput.on('change', function (){
            var img = productBackgroundImage;
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function (e) {
                img.css({
                    'background-image': 'url(' + e.target.result + ')'
                });
            };
            reader.readAsDataURL(file);
        });

        /**
         *	Fejlécben található tétel név szerkesztése.
         */
        function _setCarretPosition(selector) {
            var node = document.querySelector('.' + selector + ' span'),
                textNode = node.firstChild,
                caret = textNode.length,
                range = document.createRange(),
                sel = window.getSelection();


            node.focus();

            range.setStart(textNode, caret);
            range.setEnd(textNode, caret);

            sel.removeAllRanges();
            sel.addRange(range);
        }

        $('.page-header-generic__text-edit-icon').on('click', function() {

            $(this).parents('.editable-content').find('span').attr('contenteditable', 'true')

            _setCarretPosition($(this).parents('.editable-content').attr('class').split(' ')[0]);

            if ( $(this).parents('.editable-content').find('span').html() == $(this).attr('data-placeholder') ) {
                $(this).parents('.editable-content').find('span').html('');;
            }

            $(this).parents('.editable-content').find('span').keypress(function(e){ return e.which != 13; });
        });

        $('.js-editable-content').on('input', function() {

            var inputItem = $($(this).attr('data-connected-field'))

            inputItem.parents('.mdl-textfield').addClass('is-focused');

            var updateString = String($(this).text().trim());
            /*
                        if($($(this).attr('data-connected-field')) == 'true') {
                            _updateUrl(updateString);
                        }
            */
            setTimeout(function() {
                inputItem.val(updateString);
            }, 400);
        });

        /**
         *	Űrlapon található tétel név szerkesztése.
         */
        $('body').on('keyup', '.js-update-editable-content', function() {
            var _self = $(this),
                updatedField = $($(this).attr('data-connected-field'));
            setTimeout(function() {
                var updateString = String(_self.val().trim());
                updatedField.text(updateString);

                // _updateUrl(updateString);
            }, 400);
        });

        /**
         * Image Gallery
         */
        productMediaUploadToggle.on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass('is-active');
            $(this).parents('.mdl-content-header').next('.mdl-content').find('.gallery-dropzone').toggleClass('is-active');
        });

        Dropzone.autoDiscover = false;

        var mediaProductImagesDropzone = $("div.js-dropzone-media-gallery-images").dropzone({
            dictDefaultMessage: GLOBAL_MESSAGES.global_dropzone_default_message,
            url: '/',
            addRemoveLinks: true,
            acceptedFiles: "image/jpeg,image/png,image/gif",
            maxFiles: 30,
            //dictDefaultMessage: '',
            init: function() {
                this.options.url = this.element.dataset.url;
                this.options.gallery = this.element.dataset.gallery;
            },
            sending: function(file, xhr, formData) {
                formData.append('tag', $('.js-media-item-image-tag-selector[data-id="'+this.options.gallery+'"]').val());
            },
            success: function( file, response ){
                if ( response.filename == '' ) {
                    this.removeFile(file);
                } else {
                    $('#js-dropzone-media-gallery-images-container-' + this.options.gallery).append(response);

                    this.removeFile(file);
                }
            }
        });


        // Products / Product Images / Select All
        productMediaImagesSelectAll.on('click', function() {
            var _gallery = $(this).attr('data-id');
            var _tag = $(this).closest('.mdl-content').find('.js-media-item-image-tag-selector').val();

            $(this).toggleClass('is-active');

            if ( $(this).hasClass('all') ) {
                $('#js-dropzone-media-gallery-images-container-'+_gallery+' .js-media-product-images-image-block[data-tag-id="'+_tag+'"] input[type="checkbox"]').prop('checked', false);
                $(this).removeClass('all')
            } else {
                $('#js-dropzone-media-gallery-images-container-'+_gallery+' .js-media-product-images-image-block[data-tag-id="'+_tag+'"] input[type="checkbox"]').prop('checked', true);
                $(this).addClass('all')
            }
            return false;
        });

        // Products / Product Images / Delete
        productMediaImagesDelete.on('click', function() {
            var _gallery = $(this).attr('data-id');

            var images = $('#js-dropzone-media-gallery-images-container-'+_gallery+' input[type="checkbox"]:checked').map(function(){
                return $(this).closest('.js-media-product-images-image-block').attr('data-image-id');
            }).get();

            if ( images.length == 0 ) {
                sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_images_not_selected, "error");
                return false;
            }

            swal({
                    title: GLOBAL_MESSAGES.global_are_you_sure,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#F44336",
                    confirmButtonText: GLOBAL_MESSAGES.global_yes_do_it,
                    closeOnConfirm: true
                },
                function(){
                    $.each(images, function(index, image) {
                        $('div.js-media-product-images-image-block[data-image-id="'+image+'"]').remove();
                    });

                    $.ajax({
                        url: productMediaImagesDelete.attr('data-url'),
                        type: 'POST',
                        data: {
                            images: images
                        },
                        success: function(data, textStatus, xhr) {
                        },
                        error: function(xhr, status, error) {
                        }
                    });

                });

            return false;
        });

        // Products / Product Images / Select galleries
        productMediaImagesTagSelect.on('change', function() {
            var _tag_id = $(this).val();
            var _gallery = $(this).attr('data-id');

            $.each($('div#js-dropzone-media-gallery-images-container-'+_gallery+' div.js-media-product-images-image-block'), function(index, image) {
                if ( _tag_id == '0' || $(this).attr('data-tag-id') == _tag_id) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });
        productMediaImagesTagSelect.trigger('change');

        // Products / Product Videos / Select galleries
        productMediaVideosTagSelect.on('change', function() {
            var _tag_id = $(this).val();
            var _gallery = $(this).attr('data-id');

            $.each($('div.js-media-product-videos-tag-block[data-id="'+_gallery+'"]'), function(index, image) {
                if ( $(this).attr('data-tag-id') == _tag_id) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });
        productMediaVideosTagSelect.trigger('change');

        /**
         *	Videó cover fotó feltöltés
         */
        $('body').on('change', '.js-media-video-cover-input', function (){
            var formData = new FormData();
            formData.append('file', $(this)[0].files[0]);
            var e = $(this);

            $.ajax({
                url: $(this).attr('data-save-url'),
                type: 'POST',
                data:  formData,
                cache: false,
                contentType: false,
                processData: false,
                beforeSend:function(jqXHR, settings){
                    //$('.toolbar .mdl-spinner').addClass('is-active');
                },
                success: function(data, textStatus, jqXHR) {
                    if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
                        e.closest('.form-data-row').find('.js-media-product-videos-image img').attr('src', data.filename_path);
                        e.closest('.form-data-row').find('.js-media-product-videos-image-input').val(data.filename);
                        e.closest('.form-data-row').find('.js-media-product-videos-image-oname-input').val(data.filename_orig);
                    }
                }
            });
        });

        /**
         *	Youtube videó behúzás
         */
        $('body').on('click', '.js-media-product-videos-url-load', function (){
            var youtube_url = $(this).closest('.form-data-row').find('.js-media-product-videos-url-input').val();
            var youtube_parsed = _getYouTubeUrl(youtube_url);
            var e = $(this);

            if ( youtube_parsed === false ) {
                e.closest('.form-data-row').find('.js-media-product-videos-play').hide();
                sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_youtube_url_invalid, "error");
                return false;
            }

            $.ajax({
                url: $(this).attr('data-save-url'),
                type: 'POST',
                data:  {
                    youtube_id: youtube_parsed.id
                },
                success: function(data, textStatus, jqXHR) {

                    if ( typeof data.valid !== 'undefined' && data.valid == false ) {
                        e.closest('.form-data-row').find('.js-media-product-videos-play').hide();
                        sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_youtube_id_invalid, "error");
                        return false;
                    }
                    if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
                        e.closest('.form-data-row').find('.js-media-product-videos-image img').attr('src', data.filename_path);
                        e.closest('.form-data-row').find('.js-media-product-videos-image-input').val(data.filename);
                        e.closest('.form-data-row').find('.js-media-product-videos-image-oname-input').val(data.filename_orig);

                        e.addClass('checked');
                        e.closest('.form-data-row').find('.js-media-product-videos-play').show();

                        e.removeClass('mdl-textfield__icon--blue').addClass('mdl-textfield__icon--green');
                        e.find('.material-icons').text('check_circle');
                    }
                }
            });

            return false;
        });

        /**
         *	Youtube videó lejátszás
         */
        $('body').on('click', '.js-media-product-videos-play', function (){
            var youtube_url = $(this).closest('.form-data-row').find('.js-media-product-videos-url-input').val();

            $.magnificPopup.open({
                items: {
                    src: youtube_url
                },
                type: 'iframe',
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">'+
                    '<div class="mfp-close"></div>'+
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                    '</div>',
                    patterns: {
                        youtube: {
                            index: 'youtube.com/',
                            id: 'v=',
                            src: '//www.youtube.com/embed/%id%?autoplay=1'
                        },
                        youtube_short: {
                            index: 'youtu.be/',
                            id: 'youtu.be/',
                            src: '//www.youtube.com/embed/%id%?autoplay=1'
                        }
                    },
                    srcAction: 'iframe_src',
                }
            });

            return false;
        });

        /**
         *	eBook cover fotó feltöltés
         */
        $('body').on('change', '.js-media-files-cover-input', function (){
            var formData = new FormData();
            formData.append('file', $(this)[0].files[0]);
            var e = $(this);

            $.ajax({
                url: $(this).attr('data-save-url'),
                type: 'POST',
                data:  formData,
                cache: false,
                contentType: false,
                processData: false,
                beforeSend:function(jqXHR, settings){
                    //$('.toolbar .mdl-spinner').addClass('is-active');
                },
                success: function(data, textStatus, jqXHR) {
                    if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
                        e.closest('.form-data-row').find('.js-media-product-files-image img').attr('src', data.filename_path);
                        e.closest('.form-data-row').find('.js-media-product-files-image-input').val(data.filename);
                        e.closest('.form-data-row').find('.js-media-product-files-image-oname-input').val(data.filename_orig);
                    }
                }
            });
        });

        /**
         *	eBook fájl feltöltés
         */
        $('body').off('change.js-media-files-ebook-type-file');
        $('body').on('change', '.js-media-files-ebook-type-file', function (){
            var formData = new FormData();
            formData.append('file', $(this)[0].files[0]);
            var e = $(this);

            $.ajax({
                url: $(this).attr('data-save-url'),
                type: 'POST',
                data:  formData,
                cache: false,
                contentType: false,
                processData: false,
                beforeSend:function(jqXHR, settings){
                    //$('.toolbar .mdl-spinner').addClass('is-active');
                },
                success: function(data, textStatus, jqXHR) {
                    if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
                        e.closest('.js-media-files-file-container').find('.js-media-product-files-name-input').val(data.filename_orig);
                        e.closest('.js-media-files-file-container').find('.js-media-files-ebook-input').val(data.filename);
                        e.closest('.js-media-files-file-container').find('.js-media-files-ebook-oname-input').val(data.filename_orig);

                        e.closest('.js-media-files-file-container').find('.js-media-file-upload-button-container').hide();
                        e.closest('.js-media-files-file-container').find('.js-media-file-ebook-delete').show();
                    }
                }
            });
        });

        $('body').off('click.js-media-file-ebook-delete');
        $('body').on('click', '.js-media-file-ebook-delete', function (e){
            e.preventDefault();

            $(this).closest('.js-media-files-file-container').find('.js-media-product-files-name-input').val('');
            $(this).closest('.js-media-files-file-container').find('.js-media-files-ebook-input').val('');
            $(this).closest('.js-media-files-file-container').find('.js-media-files-ebook-oname-input').val('');

            $(this).hide();
            $(this).closest('.js-media-files-file-container').find('.js-media-file-upload-button-container').show();
        });


    }

    function _getYouTubeUrl(url) {
        var videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        if(videoid != null) {
            return {
                valid: true,
                id: videoid[1]
            };

            // return videoid[1];
        } else {
            return false;
        }
    }

    return {

        init: init,

	}

}(jQuery));