var ordersModuleHelper = (function($) {

	'use strict';

	function init() {

        // Set new status
        $('#details-panel').on('click', '.js-order-update-status', function (){
            if ( $(this).attr('data-status-orig') == $('.js-order-new-status-block #new_status').val() ) {
                swal({
                    title: GLOBAL_MESSAGES.global_oops,
                    text: GLOBAL_MESSAGES.orders_status_same,
                    type: "error",
                    html: true
                });
                return false;
            }

            $.ajax({
                url: $(this).attr('href'),
                type: 'POST',
                data: {
                    status_id: $('.js-order-new-status-block #new_status').val()
                },
                success: function(data, textStatus, xhr) {
                    ajaxHelper.loadContent({
                        _url: window.location.href,
                        _force_redirect: '0',
                        _url_title: 'preserve',
                        _callback: '',
                        _container: 'js-main-container',
                        _push_state: 'false'
                    });
                },
                error: function(xhr, status, error) {
                    var response = $.parseJSON(xhr.responseText);
                    sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
                }
            });

            return false;
        });

        /**
         * Dashboard
         */
        // Overview
        $('body').off('click.js-shop-dashboard-overview-interval');        
        $('body').on('click.js-shop-dashboard-overview-interval', 'a.js-shop-dashboard-overview-interval', function (){
            $('.js-shop-dashboard-overview-interval').removeClass('active');
            $(this).addClass('active');
            $('#js-shop-dashboard-overview-wrapper').addClass('is-loading');

            $('input[name="overview_interval"]').val( $(this).attr('data-interval') );
            $('input[name="overview_custom_date"]').val('')
            _getDashboardOverviewTable();
            return false;
        });
        $('body').off('click.js-shop-dashboard-overview-list');        
        $('body').on('click.js-shop-dashboard-overview-list', 'a.js-shop-dashboard-overview-list', function (){
            $('.js-shop-dashboard-overview-list').removeClass('is-active');
            $(this).addClass('is-active');
            $('#js-shop-dashboard-overview-wrapper').addClass('is-loading');

            $('input[name="overview_list"]').val( $(this).attr('data-list') );
            _getDashboardOverviewTable();
            return false;
        });
        $('#js-shop-dashboard-overview-wrapper').off('apply.daterangepicker'); 
        $('#js-shop-dashboard-overview-wrapper').on('apply.daterangepicker', '.daterange-from-to', function(ev, picker) {
            $('.js-shop-dashboard-overview-interval').removeClass('active');
            $('#js-shop-dashboard-overview-wrapper').addClass('is-loading');

            $('input[name="overview_interval"]').val('custom');

            _getDashboardOverviewTable();           
        });
        _getDashboardOverviewTable();

        // Flows
        $('body').off('click.js-shop-dashboard-flows-interval');        
        $('body').on('click.js-shop-dashboard-flows-interval', 'a.js-shop-dashboard-flows-interval', function (){
            $('.js-shop-dashboard-flows-interval').removeClass('active');
            $(this).addClass('active');
            $('#js-shop-dashboard-flows_list-wrapper').addClass('is-loading');
            
            $('input[name="flows_interval"]').val( $(this).attr('data-interval') );
            $('input[name="flows_custom_date"]').val('')            
            _getFlowsContent();
            return false;
        });
        $('body').off('click.js-shop-dashboard-flows-list');        
        $('body').on('click.js-shop-dashboard-flows-list', 'a.js-shop-dashboard-flows-list', function (){
            $('.js-shop-dashboard-flows-list').removeClass('is-active');
            $(this).addClass('is-active');
            $('#js-shop-dashboard-flows_list-wrapper').addClass('is-loading');
                        
            $('input[name="flows_list"]').val( $(this).attr('data-list') );
            _getFlowsContent();
            return false;
        });
        $('#js-shop-dashboard-flows_list-wrapper').off('apply.daterangepicker'); 
        $('#js-shop-dashboard-flows_list-wrapper').on('apply.daterangepicker', '.daterange-from-to', function(ev, picker) {
            $('.js-shop-dashboard-flows-interval').removeClass('active');
            $('#js-shop-dashboard-flows_list-wrapper').addClass('is-loading');

            $('input[name="flows_interval"]').val('custom');

            _getFlowsContent();           
        })
        _getFlowsContent();

        // Top 5 Product
        $('body').off('click.js-shop-dashboard-top5product-interval');        
        $('body').on('click.js-shop-dashboard-top5product-interval', 'a.js-shop-dashboard-top5product-interval', function (){
            $('.js-shop-dashboard-top5product-interval').removeClass('active');
            $(this).addClass('active');
            $('#js-shop-dashboard-top5product-wrapper').addClass('is-loading');

            $('input[name="top5product_interval"]').val( $(this).attr('data-interval') );
            $('input[name="top5product_custom_date"]').val('')                        
            _getDashboardTop5ProductContent();
            return false;
        });
        $('#js-shop-dashboard-top5product-wrapper').off('apply.daterangepicker'); 
        $('#js-shop-dashboard-top5product-wrapper').on('apply.daterangepicker', '.daterange-from-to', function(ev, picker) {
            $('.js-shop-dashboard-top5product-interval').removeClass('active');
            $('#js-shop-dashboard-top5product-wrapper').addClass('is-loading');

            $('input[name="top5product_interval"]').val('custom');

            _getDashboardTop5ProductContent();           
        })     
        $('.js-shop-dashboard-top5product-interval.active').click();

    }

    /**
     * Dashboard Overview táblázat frissítése
     */
    function _getDashboardOverviewTable() {  
        var _interval = $('input[name="overview_interval"]').val();
        var _custom_interval = $('input[name="overview_custom_date"]').val();
        var _list = $('input[name="overview_list"]').val();

        $.ajax({
            url: $('#js-shop-dashboard-overview-table').attr('data-url'),
            type: 'POST',
            data: {
                interval: _interval,
                custom_interval: _custom_interval,
                list: _list
            },
            success: function(data, textStatus, xhr) {
                $('.js-shop-dashboard-overview-table-empty-msg').hide();
                $('#js-shop-dashboard-overview-table').html(data);
                $('#js-shop-dashboard-overview-wrapper').removeClass('is-loading');
            },
            error: function(xhr, status, error) {
                var response = $.parseJSON(xhr.responseText);
                sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
            }
        });
    }

    /**
     * Flows frissítése
     */
    function _getFlowsContent() {  
        var _interval = $('input[name="flows_interval"]').val();
        var _custom_interval = $('input[name="flows_custom_date"]').val();        
        var _list = $('input[name="flows_list"]').val();

        $.ajax({
            url: $('#js-shop-dashboard-flows-table').attr('data-url'),
            type: 'POST',
            data: {
                interval: _interval,
                custom_interval: _custom_interval,
                list: _list
            },
            success: function(data, textStatus, xhr) {
                $('#js-shop-dashboard-flows-table').html(data);
                $('#js-shop-dashboard-flows_list-wrapper').removeClass('is-loading');
            },
            error: function(xhr, status, error) {
                var response = $.parseJSON(xhr.responseText);
                sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
            }
        });       
    }

    /**
     * Dashboard TOP 5 Product táblázat frissítése
     */
    function _getDashboardTop5ProductContent() {  
        var _interval = $('input[name="top5product_interval"]').val();
        var _custom_interval = $('input[name="top5product_custom_date"]').val();

        $.ajax({
            url: $('#js-shop-dashboard-top5product-table').attr('data-url'),
            type: 'POST',
            data: {
                interval: _interval,
                custom_interval: _custom_interval,                
            },
            success: function(data, textStatus, xhr) {
                $('#js-shop-dashboard-top5product-table').html(data);
                $('#js-shop-dashboard-top5product-wrapper').removeClass('is-loading');
            },
            error: function(xhr, status, error) {
                var response = $.parseJSON(xhr.responseText);
                sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
            }
        });
    }

    return {

        init: init,

	}

}(jQuery));