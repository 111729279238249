var systemToolsModuleHelper = (function($) {

	'use strict';

	function init() {

        $('.js-send-email-checkbox input[type="radio"]').on('change', function() {
            if($(this).val() == '1') {
                $('.js-email-template-container').show();
            } else {
                $('.js-email-template-container').hide();
            }
        });

    }

    return {

        init: init,

	}

}(jQuery));