
/**
 * Postfix megjelenítése a szükséges űrlapokon.
 */

var postfixHelper = (function($) {

	'use strict';

	function _postFixHandler(_self) {
		var parentItem = _self.parents('.mdl-textfield'),
			refItem = $('<p>' + _self.val() + '</p>'),
			postfixItem = $('<span>' + _self.attr('data-postfix') + '</span>'),
			extraClass = _self.attr('data-postfix-extraclass');

		parentItem.find('.mdl-textfield__postfix').remove();

		refItem.attr({
			'class': 'ref-text'
		});

		postfixItem.attr({
			'class': 'mdl-textfield__postfix'
		});

		parentItem.append(refItem);
		parentItem.append(postfixItem);

		if((parentItem.width() - parentItem.find('.mdl-textfield__postfix').width() - 10) < parentItem.find('.ref-text').width()) {
			parentItem.find('.mdl-textfield__postfix').addClass('is-fixed');
		} else {
			parentItem.find('.mdl-textfield__postfix').removeClass('is-fixed');
		}
		if (extraClass != '')  {
            parentItem.find('.mdl-textfield__postfix').addClass(extraClass);
        }

		parentItem.find('.mdl-textfield__postfix').css({
			'left': parentItem.find('.ref-text').width() + 5
		});

		parentItem.find('.ref-text').remove();
	}

	function init() {

		var postfixItems = $('.postfixer');
 
		postfixItems.each(function() {
			_postFixHandler($(this));
		});

		postfixItems.on('keyup', function() {
			_postFixHandler($(this));
		});

	}

	return {

		init: init

	}

}(jQuery));


var postfixHelperInline = (function($) {

	'use strict';

	function _postFixHandler(_self) {
		var fix = _self.attr('data-fix'),
			fixType = _self.attr('data-fix-type');

		if(fixType === 'postfix') {
			fix = fix + ' ';

			_self.inputmask("numeric", {
				radixPoint: ".",
				groupSeparator: "",
				digits: 2,
				autoGroup: true,
				prefix: fix,
				rightAlign: false,
				allowPlus: true,
				allowMinus: true,
				oncleared: function() { self.Value(''); }
			});

		} else {
			fix = ' ' + fix;

			_self.inputmask("numeric", {
				radixPoint: ".",
				groupSeparator: "",
				digits: 2,
				autoGroup: true,
				suffix: fix,
				rightAlign: false,
				allowPlus: true,
				allowMinus: true,
				oncleared: function() { self.Value(''); }
			});

		}
	}

	function init() {

		$('.postfixer-inline').each(function() {
			_postFixHandler($(this));
		});

	}

	return {

		init: init

	}

}(jQuery));





/**
 * Input mezők karakter számának számolása.
 */

var charCounterHelper = (function($) {

	'use strict';

	function _setCounter(_self) {
		var input = _self.find('.length-counter__input'),
			limit = input.attr('maxlength'),
			displayBlock = _self.find('.length-counter__display'),
			currentText = _self.find('.length-counter__current'),
			textLength = 0,
			textRemaining = 0;

		_refreshHelper(input, currentText);

		displayBlock.addClass('is-visible');

		input.on('keyup', function() {
			var textLength = $(this).val().length;

        	currentText.text(textLength + _newLineHelper($(this)));

        	if(limit == textLength + _newLineHelper($(this))) {
        		displayBlock.addClass('is-limit');
        	} else {
        		displayBlock.removeClass('is-limit');
        	}
		});
	}

	function _newLineHelper(_self) {
		var nl = 0;

		if(_self.val().lastIndexOf('\n') === -1) {
			return 0;
		}

		if (_self.val().lastIndexOf('\n') !== -1) {
    		nl = _self.val().split('\n');
		}

		return nl.length - 1;
	}

	function _refreshHelper(input, currentText) {
		var textLength = input.val().length;

		currentText.text(textLength);
	}

	function init() {
		var items = $('.length-counter');

		items.each(function() {
			_setCounter($(this));
		});
	}

	return {

		init: init

	}

}(jQuery));





/**
 * Select2 megjelenítése a szükséges űrlapokon.
 */

var select2 = (function($) {

	'use strict';

	function relatedProductStateBadge (state, container) {
		if (!state.id) { return state.text; }

		$(container).attr('data-type', state.element.dataset.type);

		$(container).attr('data-id', state.element.value);

		if ( typeof state.element.dataset.image !== 'undefined' ) {
			if(state.element.value === 'empty') {
				var $state = $(
					'<span><span class="select-text">' + state.text + '</span></span>'
				);
			} else {
				var $state = $(
					'<span class="preview-wrapper"><img class="img-preview" src="' + state.element.dataset.image + '" /><span class="preview-select-text">' + state.text + '</span></span>'
				);
			}
		} else {
			var $state = $(
				'<span><span class="select-text">' + state.text + '</span></span>'
			);			
		}

		return $state;
	};

	function relatedProductStateBadgeSelection (state, container) {
		if (!state.id) { return state.text; }

		$(container).attr('data-type', state.element.dataset.type);

		$(container).attr('data-id', state.element.value);

		var $state = $(
			'<span><span class="select-text">' + state.text + '</span></span>'
		);

		return $state;
	}

	function formatStateBadge (state) {
		if (!state.id) { return state.text; }

		if(state.element.value === 'empty') {
			var $state = $(
				'<span><span class="select-text">' + state.text + '</span></span>'
			);
		} else {
			var $state = $(
				'<span><span class="img-badge icon-' + state.element.value + '"></span><span class="select-text">' + state.text + '</span></span>'
			);
		}
		
		return $state;
	};

	function init() {

		// Badge selects
		
		$('.select2-simple').not('.is-setting-block').select2({
			minimumResultsForSearch: -1
		});

		$(".select2-simple--badge").not('.is-setting-block').select2({
			minimumResultsForSearch: -1,
			templateResult: formatStateBadge,
			templateSelection: formatStateBadge
		});

		$("select").on("select2:open", function (e) {
			var psContainer = document.querySelector('.select2-results__options');

			$('body > .select2-container--open').css({
				'color': $(this).parents('.mdl-textfield').attr('data-color')
			});

			Ps.destroy(psContainer);
			
			setTimeout(function() {
				Ps.initialize(psContainer, {
					wheelSpeed: 1,
					wheelPropagation: true,
					minScrollbarLength: 20
				});
			}, 0);

		});

		$('.select2-simple--with-search').select2({});

		$('.select2-simple--multiselect').not('.is-setting-block').select2({
			minimumResultsForSearch: -1,
			placeholder: "Select an option"
		});

		$('.select2-simple--multiselect-everywhere').not('.is-setting-block').select2({
			minimumResultsForSearch: -1,
			placeholder: "Everywhere"
		});

		$('.select2-simple--multiselect--with-search').select2({
			placeholder: "Select an option"
		});

		// Select with no auto close

		$('.select2-simple--multiselect--no-close').select2({
			minimumResultsForSearch: -1,
			placeholder: "Select an option",
			closeOnSelect: false
		});

		// Related product select
		
		$('.select2--is-sortable').not('.is-setting-block').select2({
			minimumResultsForSearch: -1,
			placeholder: "Search...",
			closeOnSelect: true,
			templateSelection: relatedProductStateBadgeSelection,
			templateResult: relatedProductStateBadge
		});
		
		$('.select2--is-sortable-max-3').not('.is-setting-block').select2({
			maximumSelectionLength: 3,
			minimumResultsForSearch: -1,
			placeholder: "Search...",
			closeOnSelect: true,
			templateSelection: relatedProductStateBadgeSelection,
			templateResult: relatedProductStateBadge
		});

		// Dynamic selects

		$('.form-data-row').not('.js-form-data-row-reference').find('.select2-simple.is-setting-block').select2({
			minimumResultsForSearch: -1
		});

		$('.form-data-row').not('.js-form-data-row-reference').find('.select2-simple--multiselect.is-setting-block').select2({
			minimumResultsForSearch: -1
		});

		$('.select2--is-sortable').parent().find("ul.select2-selection__rendered").sortable({
		    forcePlaceholderSize: true,
		    tolerance   : 'pointer',
		    items: 'li:not(.select2-search--inline)',
		    update: function() {
		    	select2OrderSortedValues($(this));
		    }
		});
		$('.select2--is-sortable-max-3').parent().find("ul.select2-selection__rendered").sortable({
		    forcePlaceholderSize: true,
		    tolerance   : 'pointer',
		    items: 'li:not(.select2-search--inline)',
		    update: function() {
		    	select2OrderSortedValues($(this));
		    }
		});

	}

	function select2OrderSortedValues(item) {
		item.children("li[title]").each(function(i, obj){
			var element = item.parents('.select2').prev('select').children("option[value=" + obj.dataset.id + "]");
        	moveElementToEndOfParent(element)
	    });
	}

	function moveElementToEndOfParent(element) {
		var parent = element.parent();
	    element.detach();
	    parent.append(element);
	};

	function settingsBlockInit() {

		$('.form-data-row').not('.js-form-data-row-reference').find('.select2-simple.is-setting-block').select2({
			minimumResultsForSearch: -1
		});
		
		$('.form-data-row').not('.js-form-data-row-reference').find('.select2-simple--multiselect.is-setting-block').select2({
			minimumResultsForSearch: -1,
			placeholder: "Select an option"
		});

		$('.form-data-row').not('.js-form-data-row-reference').find('.select2--is-sortable.is-setting-block').select2({		
			minimumResultsForSearch: -1,
			placeholder: "Search...",
			closeOnSelect: true,
			templateSelection: relatedProductStateBadgeSelection,
			templateResult: relatedProductStateBadge
		});

		$('.form-data-row').not('.js-form-data-row-reference').find('.select2--is-sortable.is-setting-block').parent().find("ul.select2-selection__rendered").sortable({
		    forcePlaceholderSize: true,
		    tolerance   : 'pointer',
		    items: 'li:not(.select2-search--inline)',
		    update: function() {
		    	select2OrderSortedValues($(this));
		    }
		});

		$('.form-data-row').not('.js-form-data-row-reference').find('.select2-simple--badge.is-setting-block').select2({
			minimumResultsForSearch: -1,
			templateResult: formatStateBadge,
			templateSelection: formatStateBadge
		});

	}

	return {

		init: init,
		settingsBlockInit: settingsBlockInit,
		relatedProductStateBadgeSelection: relatedProductStateBadgeSelection,
		relatedProductStateBadge: relatedProductStateBadge,
		select2OrderSortedValues: select2OrderSortedValues

	}

}(jQuery));





/**
 * Pickadate naptár bővítmény kezelése.
 */
var datepickerHelper = (function($) {

	function init() {
		if($('.datepicker').length > 0) {
			var input = $('.datepicker').pickadate({
				format: 'mmm dd, yyyy',
  				formatSubmit: 'yyyy-mm-dd',
				weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
				showMonthsShort: true
			});

			var picker = input.pickadate('picker');

			if ( input.val() != '' ) {
				picker.set('select', input.val(), { format: 'yyyy-mm-dd' });
			}
			
			picker.on({
				close: function() {
					var inputItem = $(this.$node[0]);

					if(inputItem.val().length > 0) {
						inputItem.parents('.mdl-textfield').removeClass('is-focused');
						inputItem.parents('.mdl-textfield').addClass('is-dirty');
					} else {
						inputItem.parents('.mdl-textfield').removeClass('is-focused');
						inputItem.parents('.mdl-textfield').removeClass('is-dirty');
					}
				},
			});
		}

		if($('.datepicker--with-date-select').length > 0) {
            $('.datepicker--with-date-select').each(function(index, element) {
				var input = $(element).pickadate({
					format: 'mmm dd, yyyy',
					formatSubmit: 'yyyy-mm-dd',
					weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
					showMonthsShort: true,
					selectYears: 100,
					selectMonths: true
				});

				var picker = input.pickadate('picker');

				if ( input.val() != '' ) {
					picker.set('select', input.val(), { format: 'yyyy-mm-dd' });
				}

				picker.on({
					close: function() {
						var inputItem = $(this.$node[0]);

						if(inputItem.val().length > 0) {
							console.log('item val', inputItem);
							inputItem.parents('.mdl-textfield').removeClass('is-focused');
							inputItem.parents('.mdl-textfield').addClass('is-dirty');
						} else {
							inputItem.parents('.mdl-textfield').removeClass('is-focused');
							inputItem.parents('.mdl-textfield').removeClass('is-dirty');
						}
					},
				});
			});
		}

		if($('.datepicker--with-date-select-past').length > 0) {
			var input = $('.datepicker--with-date-select-past').pickadate({
				format: 'mmm dd, yyyy',
  				formatSubmit: 'yyyy-mm-dd',
				weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
				showMonthsShort: true,
                selectYears: 100,
                max: true,
                selectMonths: true
			});

			var picker = input.pickadate('picker');

			if ( input.val() != '' ) {
				picker.set('select', input.val(), { format: 'yyyy-mm-dd' });
			}

			picker.on({
				close: function() {
					var inputItem = $(this.$node[0]);

					if(inputItem.val().length > 0) {
						console.log('item val', inputItem);
						inputItem.parents('.mdl-textfield').removeClass('is-focused');
						inputItem.parents('.mdl-textfield').addClass('is-dirty');
					} else {
						inputItem.parents('.mdl-textfield').removeClass('is-focused');
						inputItem.parents('.mdl-textfield').removeClass('is-dirty');
					}
				},
			});
		}

		if($('.daterange-from-to').length > 0) {
			$('.daterange-from-to').each(function(index, element) {
				console.log('type', $(element).attr('data-type'));
				if($(element).attr('data-type') == 'dashboard-box') {
					console.log('dashboard type', 'parent: ', $(element).parents('.dashboard__box'));

					$(element).daterangepicker({
						parentEl: $(element).parents('.dashboard__box'),
			            timePicker: false,
			            timePickerIncrement: 30,
			            timePicker24Hour: true,
			            autoApply: true,
			            autoUpdateInput: false,
			            locale: {
			                format: 'DD/MM/YYYY'
			            }
			        });
				} else {
					$(element).daterangepicker({
						parentEl: '.dashboard__counter-box[data-dropdown-id="' + $(element).attr('data-dropdown-id') + '"]',
			            timePicker: false,
			            timePickerIncrement: 30,
			            timePicker24Hour: true,
			            autoApply: true,
			            autoUpdateInput: false,
			            locale: {
			                format: 'DD/MM/YYYY'
			            }
			        });
				}
			});

			$('.daterange-from-to').on('apply.daterangepicker', function(ev, picker) {
				$(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
				$(this).parents('.mdl-js-textfield').addClass('is-dirty');

			});

			$('.daterange-from-to').on('cancel.daterangepicker', function(ev, picker) {
				$(this).val('');
				$(this).parents('.mdl-js-textfield').removeClass('is-dirty');
			});
			
        }

        if($('.daterange-from-to--timepicker').length > 0) {
            $('.daterange-from-to--timepicker').each(function(index, element) {
				$(element).daterangepicker({
					timePicker: true,
                    timePickerIncrement: 5,
                    timePicker24Hour: true,
					autoApply: true,
					drops: 'up',
                    autoUpdateInput: false,
					locale: {
						format: 'YYYY.MM.DD H:mm'
					}
				});
                $(element).on('apply.daterangepicker', function(ev, picker) {
                    $(this).val(picker.startDate.format('YYYY.MM.DD H:mm') + ' - ' + picker.endDate.format('YYYY.MM.DD H:mm'));
                });
            });
        }

	}

	function uniqueDaterangepickerReinit(element) {
		element.find('.daterange-from-to').daterangepicker({
			parentEl: element.find('.dashboard__counter-box'),
            timePicker: false,
            timePickerIncrement: 30,
            timePicker24Hour: true,
            autoApply: true,
            autoUpdateInput: false,
            locale: {
                format: 'DD/MM/YYYY'
            }
        });

    	element.find('.daterange-from-to').on('apply.daterangepicker', function(ev, picker) {
			$(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
			$(this).parents('.mdl-js-textfield').addClass('is-dirty');

		});

		element.find('.daterange-from-to').on('cancel.daterangepicker', function(ev, picker) {
			$(this).val('');
			$(this).parents('.mdl-js-textfield').removeClass('is-dirty');
		});
	}

	return {
		init: init,
		uniqueDaterangepickerReinit: uniqueDaterangepickerReinit
	}

}(jQuery));





/**
 * Kép feltölés előnézettel.
 */
var imageUploaderPreviewHelper = (function($) {

	function init() {
		var imageUploadWrapper = $('.image-upload-wrapper');

		imageUploadWrapper.each(function() {

			var imageUploadWrapperInput = $(this).find('input[type="file"]'),
			    imageUploadWrapperInputHidden = $(this).find('.image-upload-wrapper__input_hidden'),
				imageUploadWrapperImage = $(this).find('.image-upload-wrapper__image'),
				imageUploadWrapperUploadIcon = $(this).find('.image-upload-wrapper__icon'),
				imageUploadWrapperDeleteIcon = $(this).find('.image-upload-wrapper__delete-icon'),
				imageUploadWrapperDeleteCheckbox = $(this).find('.image-upload-wrapper__is-delete'),
				imageUploadWrapperImageWrapper = $(this).find('.image-upload-wrapper__image-wrapper'),
				_self = $(this),
				validate = $(this).attr('data-validate'),
				emptyPreview = $(this).attr('data-empty-preview');


			imageUploadWrapperDeleteIcon.on('click', function() {
				imageUploadWrapperDeleteCheckbox.prop( 'checked' , true );
				imageUploadWrapperDeleteIcon.attr('hidden', '');
				imageUploadWrapperUploadIcon.removeAttr('hidden');
                imageUploadWrapperInput.val('');

				imageUploadWrapperImage.attr('src', emptyPreview);
			});

			/**
			 *	Avatar feltöltésének, behelyettesítésének a lekezelése.
			 */
			imageUploadWrapperInput.on('change', function (){

		        var img = imageUploadWrapperImage,
		        	file = this.files[0];
		        	
				imageUploadWrapperDeleteCheckbox.prop( 'checked' , false );

		        if(validate === 'true') {
		        	if (file.size/1024 > _self.attr('data-upload-limit')) {
			        	_self.addClass('is-error')
			        } else {
			        	_self.removeClass('is-error');

						var formData = new FormData();
						formData.append('file', $(this)[0].files[0]);
						var element = $(this);

						$.ajax({
				            url: $(this).attr('data-save-url'),
				            type: 'POST',
				            data:  formData,
				            cache: false,
				            contentType: false,
				            processData: false,
				            beforeSend:function(jqXHR, settings){
				            	//$('.toolbar .mdl-spinner').addClass('is-active');
				            },            
				            success: function(data, textStatus, jqXHR) {

				            	if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
				            		imageUploadWrapperImage.attr('src', data.filename_path);
				            		imageUploadWrapperInputHidden.val(data.filename);		
				            		imageUploadWrapperUploadIcon.attr('hidden', '');
				            		imageUploadWrapperDeleteIcon.removeAttr('hidden');

				            		imageUploadWrapperImageWrapper.addClass('has-file');
				            	} 
				            }        
				        });

			        }
		        }

		    });
		});


	}

	return {
		init: init
	}

}(jQuery));
