'use strict';

var debugDependencies = false;

function perfectScrollbarGlobalInit(reset) {
	
	if (settingsDebug && debugDependencies) console.log('Perfect Scrollbar Global Init');

	// pfScrollbar.init(reset);
}

function mdlGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('MDL Global Init');

	componentHandler.upgradeAllRegistered();

}

function userModuleGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('User Module Global Init');

	userModuleHelper.init();
}

function translateGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Translate Global Init');

	translateHelper.init();
}

function productModuleGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Product Module Global Init');

	productModuleHelper.init();
}

function ordersModuleGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Orders Module Global Init');

	ordersModuleHelper.init();
}

function dashboardModuleGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Dashboard Module Global Init');

	dashboardModuleHelper.init();
}

function simpleAccordionGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Simple Accordion Global Init');

	simpleAccordion.init();

}

function notificationsGlobalInit() {

    if (GLOBAL_SETTINGS.settings_debug && GLOBAL_SETTINGS.debug_dependencies) console.log('notificationsHelper Global Init');

    notificationsHelper.init()
}

function colorPickerGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Color Picker Global Init');

	$(".color-picker").spectrum("destroy");
	
	$(".color-picker").spectrum({
		preferredFormat: "hex",
    	showInput: true,
    	showPalette: true,
	    change: function(color) {
	        $(this).parent('.mdl-color-picker').find('.mdl-color-picker-value').text(color.toHexString());

		    $(this).parents('.form-data-row').find('.mdl-textfield[data-color-picker="' + $(this).attr('data-color-picker') + '"]').css({
				'color': $(this).val()
			}).attr({
				'data-color': $(this).val()
			});
	    },
	    palette: [
	        ['#E91E63', '#00C853', '#9C27B0', '#00BCD4', '#2979FF', '#0091EA', '#03A9F4', '#4CAF50', '#FF9800', '#FF5722', '#FFC107', '#37474F', '#D32F2F', '#616161', '#FAFAFA', '#EEEEEE', '#212121', '#263238'],

	    ]
	});

	$(".color-picker").each(function() {
		$(this).parent('.mdl-color-picker').find('.mdl-color-picker-value').text($(this).val());

		$(this).parents('.form-data-row').find('.mdl-textfield[data-color-picker="' + $(this).attr('data-color-picker') + '"]').css({
			'color': $(this).val()
		}).attr({
			'data-color': $(this).val()
		});
	});
}



function postfixerGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Postfixer Global Init');

	postfixHelper.init();
	postfixHelperInline.init();
}

function charCounterGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Character Counter Global Init');

	charCounterHelper.init();
}

function autosizeGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Autosize Global Init');

	autosize($('.autosize-textarea'));
}



function idleGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Idle Global Init');

	idleHelper.init();
}



function lockScreenGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Lock Screen Global Init');

	lockScreenHelper.init();
}


function datepickerGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Date Picker Global Init');

	datepickerHelper.init();
}


function dataTableGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Data Table Global Init (Sort, Delete, Pagination helper)');

	sortHelper.init();
	deleteHelper.init();
	paginationHelper.init();
	tablesawHelper.init();
}


function deleteGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Delete Global Init');

	deleteHelper.init();
}


function dragulaGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Dragula Global Init');

	uiHelper.dragulaInit();
}

function liveSearchGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Live Block Search Global Init');

	uiHelper.liveSearchInit();
}


function menuElementSearchGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Live Block Search Global Init');

	uiHelper.menuElementSearchInit();
}

function blockCollapseGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Block Collapse Global Init');

	uiHelper.blockCollapseInit();
}


function blockSettingsGlobalInit() {

	if (settingsDebug && debugDependencies) console.log('Block Settings Global Init');

	uiHelper.blockSettingsInit();
}


function c3GlobalInit() {

	if (settingsDebug && debugDependencies) console.log('C3 Global Init');

	szilardHelper.init();
}


function perfectScrollbarBlockGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('Perfect Scrollbar Block Global Init');

	szilardHelper.perfectScrollbarBlockInit();
}


function dashboardChartsGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('Highcharts Global Init');

	dashboardChartsHelper.init();
}


function select2GlobalInit() {
	if (settingsDebug && debugDependencies) console.log('Select2 Global Init');

	select2.init();
}


function dateRangePickerGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('dateRangePicker Global Init');

	szilardHelper.dateRangePickerInit();
}


function editorGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('Editor Global Init');

	froalaEditor.init();
}

function dropzoneGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('Dropzone Global Init');

	dropzone.init();
}


function msgHandlerGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('MSG Handler Global Init');

	uiHelper.msgHandler();
}


function infoboxGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('infoBox Global Init');

	infoBox.init();
}


function magnificGlobalInit() {
	if (settingsDebug && debugDependencies) console.log('magnific Global Init');

	magnific.init();
}

function matchHeightGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('matchHeight Global Init');

	uiHelper.matchHeightInit();
}

function tooltipGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('Tooltip Global Init');

	neoTooltip.init();
}

function imageUploadPreviewGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('Image Upload Preview Global Init');

	imageUploaderPreviewHelper.init();
}

function bannerModuleGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('Banner Global Init');

	bannerModuleHelper.init();
}

function settingModuleGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('Setting Global Init');

	settingModuleHelper.init();
}

function systemToolsModuleGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('System Tools Global Init');

	systemToolsModuleHelper.init();
}

function customTabGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('customTab Global Init');

	uiHelper.neoCustomTab();
}

function appearanceModuleGlobalInit(){
	if (settingsDebug && debugDependencies) console.log('Appearance Global Init');

	appearanceModuleHelper.init();
}

function mediaModuleGlobalInit(){
    if (settingsDebug && debugDependencies) console.log('Media Global Init');

    mediaModuleHelper.init();
}

