var ajaxHelper = (function($) {

	'use strict';

	if (settingsDebug) console.debug('AJAX Helper function loaded...');

	var bodyNode = $('body'),
		contentNode = $('#js-main-container'),
		currentUri = window.location.pathname,
        mainAjaxTriggers = $('.js-load-ajax-content');


	/**
	 * Aktív menü elem beállításáért felelős függvény.
	 * @param {object} _self [jQuery this objektum]
	 */
	function _setCurrentMenuItem(_self, _wrapper, _item) {

        if($(_self).parents('#js-sidenav').length > 0 || $(_self).parents('.breadcrumb').length > 0 || $(_self).parents('.toolbar__logo').length > 0) {
            $('.sidenav').find('a').removeClass('navigation-button--active');

            if (settingsDebug) console.debug('Set current item "active" class: ', _self);

            $('.' + _self.attr('class').split(' ')[0]).not('[data-submenu="true"]').addClass('navigation-button--active');

        }

		if(_wrapper && _item) {
			$('#' + _wrapper).find('a').removeClass('navigation-button--active');
			$('.' + _item).addClass('navigation-button--active');
		}
		
	}


	/**
	 * Az oldalakhoz tartozó függőségek betöltéséért felelős függvény.
	 * @param  {object} args [Dinamikus paraméter lista.]
	 */
	function loadDependencies(args) {

		if (settingsDebug) console.debug('Load Dependencies function called...');

		var empty = [],
			list = ['mdl', 'idle', 'dataTable', 'delete', 'menuElementSearch', 'msgHandler' , 'select2', 'perfectScrollbar', 'tooltip', 'notifications'],
			details = ['mdl', 'idle', 'postfixer', 'delete', 'select2', 'menuElementSearch', 'msgHandler', 'perfectScrollbar', 'tooltip', 'notifications'],			
			dependencies = [];

		/**
		 * Kivételek eltávolítása az alap lista tömbből.
		 * @param  {array} list    [Alap lista tömb]
		 * @param  {array} excepts [Kivételek tömb]
		 * @return {array}         [Visszatérési tömb]
		 */
		function _removeDependenciesHelper(list, excepts) {

			for(var i = excepts.length - 1; i >= 0; i--) {
				for(var j = list.length - 1; j >= 0; j--) {
				    if(list[j] === excepts[i]) {
				       list.splice(j, 1);
				    }
				}
			}

			return list;
		}

		switch (args.base) {
		    case 'list':
		        list = list.concat(args.additions);
		        list = _removeDependenciesHelper(list, args.excepts);

		        dependencies = list;
		        break;

		    case 'details':
		        details = details.concat(args.additions);
		        details = _removeDependenciesHelper(details, args.excepts);

		        dependencies = details;
		        break;
		    case 'empty':
		    	empty = empty.concat(args.additions);
		        empty = _removeDependenciesHelper(empty, args.excepts);

		        dependencies = empty;
		        break;
		}

		if (settingsDebug) console.debug('Loaded Dependencies: ', dependencies);

		// Függőségek betöltése a window objektumon keresztül.
		dependencies.forEach(function(element, index) {
			window[element + "GlobalInit"]();
		});
	}

    /**
     * URL kiegészítése plusz paraméterrel
     * @param  {string} url
     * @param  {string} parameterName
     * @param  {string} parameterValue
     * @param  {boolean} atStart
     */
    function addUrlParameter(url, parameterName, parameterValue, atStart){
        var replaceDuplicates = true;
        if(url.indexOf('#') > 0){
            var cl = url.indexOf('#');
            var urlhash = url.substring(url.indexOf('#'),url.length);
        } else {
            var urlhash = '';
            cl = url.length;
        }
        var sourceUrl = url.substring(0,cl);

        var urlParts = sourceUrl.split("?");
        var newQueryString = "";

        if (urlParts.length > 1)
        {
            var parameters = urlParts[1].split("&");
            for (var i=0; (i < parameters.length); i++)
            {
                var parameterParts = parameters[i].split("=");
                if (!(replaceDuplicates && parameterParts[0] == parameterName))
                {
                    if (newQueryString == "")
                        newQueryString = "?";
                    else
                        newQueryString += "&";
                    newQueryString += parameterParts[0] + "=" + (parameterParts[1]?parameterParts[1]:'');
                }
            }
        }
        if (newQueryString == "")
            newQueryString = "?";

        if(atStart){
            newQueryString = '?'+ parameterName + "=" + parameterValue + (newQueryString.length>1?'&'+newQueryString.substring(1):'');
        } else {
            if (newQueryString !== "" && newQueryString != '?')
                newQueryString += "&";
            newQueryString += parameterName + "=" + (parameterValue?parameterValue:'');
        }
        return urlParts[0] + newQueryString + urlhash;
    };

    /**
     * URL-ből paraméter törlés plusz paraméterrel
     * @param  {string} url
     * @param  {string} parameter
     */
    function removeUrlParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts= url.split('?');   
        if (urlparts.length>=2) {

            var prefix= encodeURIComponent(parameter)+'=';
            var pars= urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i= pars.length; i-- > 0;) {    
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                    pars.splice(i, 1);
                }
            }

            url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
            return url;
        } else {
            return url;
        }
    }


	/**
	 * AJAX hivásokat kezelő függvény.
	 * @param  {object} args [Dinamikusan bővíthető objektum]
	 */
	function loadContent(args) {

	    if (typeof xhr !== 'undefined') xhr.abort();

        mainAjaxTriggers.addClass('soft-disable');

        if ( args._url_title === 'preserve' ) {
            args._url_title = document.title.split('-')[0];
        }

        contentNode = $('#' + args._container);

        var load_url = args._url;
        if ( args._container == 'js-data-table' ) {
            load_url = addUrlParameter(args._url, 'table-only', '1', false);
        }

	    var xhr = $.ajax({
	        url: load_url,
	        beforeSend:function(){
	           $('.toolbar .mdl-spinner').addClass('is-active');
	           contentNode.removeClass('animate--in').addClass('animate--out');
	        },
	        success: function(data, textStatus, xhr) {

                // Nyelvi beállítások visszaállítása
                $('#body').removeClass('lang-de').removeClass('lang-ru').addClass('lang-en');

	        	contentNode.addClass('js-ajax-request');

	        	if(typeof args._self !== 'undefined') {
	        		var menuItemClasses = args._self[0]['className'].split(" ");
	        	}

	        	setTimeout(function() {

		            $('body #' + args._container).html('');

	                if(args._push_state === 'true') {
		                document.title = args._url_title + ' - ' + document.title.split('-')[1];

		                history.pushState({
		                	"location": args._url,
		                	"title": args._url_title,
		                	"menuWrapper": args._self.closest(".sidenav").attr('id'),
		                	"menuItem": menuItemClasses[0]
		                }, args._url_title, args._url);
		            }

                    $(data).appendTo('body #' + args._container);

		            contentNode.removeClass('animate--out').addClass('animate--in');
		            $('.toolbar .mdl-spinner').removeClass('is-active');

                    mainAjaxTriggers.removeClass('soft-disable');

	            }, 700);

	            if (settingsDebug) console.debug('Successful AJAX call. Current page:', args._url_title);

                if ( args._container != 'js-data-table' ) {
	               _setCurrentMenuItem(args._self, args._history_args_wrapper, args._history_args_item);
                }

	        },
	        error: function() {
	            window.location = args._url;
                mainAjaxTriggers.removeClass('soft-disable');
	        }
	    });
	}


	/**
	 * Űrlapok AJAX küldését kezelő függvény
	 * @param  {object} args [Dinamikusan bővíthető paraméter lista.]
	 */
	function postForm(args) {
		
        contentNode = $('#' + args._container);        
        
		$.ajax({
            url: args._formURL,
            type: 'POST',
            data:  args._formData,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend:function(jqXHR, settings){
            	$('.toolbar .mdl-spinner').addClass('is-active');
            },            
            success: function(data, textStatus, jqXHR) {   

                if ( args._container === 'magnific' ) {
                    var magnificPopup = $.magnificPopup.instance;
                    magnificPopup.items[magnificPopup.index].type = "inline";
                    magnificPopup.items[magnificPopup.index].src = data;
                    magnificPopup.updateItemHTML();

                    console.log('Példány:', $.magnificPopup.instance);

                    // console.log(magnificPopup.items);
/*
                    ajaxHelper.loadDependencies({
                        base: 'details',
                        additions: [],
                        excepts: []
                    });
*/                                        
                    return;
                }             

                contentNode.html('');
                $(data).appendTo(contentNode);

                if (settingsDebug) console.debug('Successful AJAX form update...');

                $('.toolbar .mdl-spinner').removeClass('is-active');

                $('#js-main-container').animate({
                  scrollTop: 0
                }, 500);

                $.event.trigger('removeSpinner');        
            }        
        });
	}


    /**
     * Űrlapok postolása után az URL és menü frissítése
     * @param  {object} args [Dinamikusan bővíthető paraméter lista.]
     */
    function setPageHistory(args) {
        
        var menuItem = $('.' + args._menuItem);
        var menuItemClasses = menuItem[0]['className'].split(" ");

        document.title = args._title + ' - ' + document.title.split('-')[1];

        history.pushState({
            "location": args._location,
            "title": args._title,
            "menuWrapper": menuItem.closest(".sidenav").attr('id'),
            "menuItem": menuItemClasses[0]
        }, args._title, args._location);

        // _setCurrentMenuItem(menuItem, '', '');
    }


	/**
	 * AJAX navigációt figyelő esemény.
	 * @param  {object}    [Esemény objektum]
	 */
	bodyNode.on('click', '.js-load-ajax-content', function(e) {

		e.preventDefault();

        // Az új tartalom betöltés kiváltásából kizárjuk a menü lenyításához használt nyilat.
        if($(e.target).hasClass('navigation-button__arrow')) {
            return;
        }

        // Csak akkor tölt újra, ha új URL-re navigálunk.
        if($(this).attr('href') === window.location.href) {
            return;
        }

        $(e.target).trigger('resetidlecounter');

        if(typeof customSearchPanel !== 'undefined') {
            customSearchPanel = undefined;
        }

        loadContent({
            _url: $(this).attr('href'),
            _force_redirect: '0',
            _url_title: $(this).attr('data-title') || 'Neocore Games',
            _callback: $(this).attr('data-callback') || '',
            _container: $(this).attr('data-container') || 'js-main-container',
            _push_state: $(this).attr('data-push-state') || 'true',
            _self: $(this)
        });

    });

    /**
     * AJAX submit gomb click figyelő esemény.
     * @param  {object}    [Esemény objektum]
     */
    bodyNode.on('click', 'button', function(e) {

        $('#js-form-submit-action').val( this.name );

        if ( $(this).attr('data-form-url') != '' ) {
            $(this).closest('form').attr('action', $(this).attr('data-form-url'));
        }

    });

    /**
	 * AJAX űrlap küldést figyelő esemény.
	 * @param  {object}    [Esemény objektum]
	 */
    bodyNode.on('submit', '.js-ajax-post-form', function(e) {

        e.preventDefault();

        var formObj = $(this),
            formURL = formObj.attr("action"),
            //formData = formObj.serialize();
            formData = new FormData(formObj[0]);

        if (settingsDebug) console.debug('Form update in progress...\n', '- URL: ' +formObj.attr("action") + '\n', '- Parameters: ' + formObj.serialize());

        if ( $(this).attr('data-submit-confirm') == '1' ) {
            swal({
              title: GLOBAL_MESSAGES.global_are_you_sure,
              //text: GLOBAL_MESSAGES.global_all_data_will_be_saved,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: GLOBAL_MESSAGES.global_yes_do_it,
              closeOnConfirm: true
            },
            function(){
                $(e.target).trigger('resetidlecounter');

                postForm({
                    _formURL: formURL,
                    _formData: formData,
                    _container: $(this).attr('data-container') || 'js-main-container',
                });                
            });
        } else {
            $(e.target).trigger('resetidlecounter');

            postForm({
                _formURL: formURL,
                _formData: formData,
                _container: $(this).attr('data-container') || 'js-main-container',
            });            
        }
    });

    /**
     * AJAX űrlap küldéskor a submit megerősítése
     * @param  {object}    [Esemény objektum]
     */
    bodyNode.on('click', '.js-ajax-post-form-submit-confirm', function(e) {
        $(this).closest('.js-ajax-post-form').attr('data-submit-confirm', 1);
    });

	/**
	 * Bögnésző előzmény navigációja által kiváltott események lekezelése.
	 * @param  {object}    [Esemény objektum]
	 */
    window.addEventListener("popstate", function(e) {

        $(e.target).trigger('resetidlecounter');

	    var state = e.state;

	    if (settingsDebug) console.debug('History navigation opbject: ', state);

	    loadContent({
            _url: state.location,
            _force_redirect: '0',
            _url_title: state.title,
            _callback: '',
            _container: 'js-main-container',
            _push_state: false,
            _history_args_wrapper: state.menuWrapper,
            _history_args_item: state.menuItem
        });

	});


    return {

		loadContent: loadContent,
        loadDependencies: loadDependencies,
        addUrlParameter: addUrlParameter,
		removeUrlParameter: removeUrlParameter,
        setPageHistory: setPageHistory

	}

}(jQuery));