var bannerModuleHelper = (function($) {

	'use strict';

	function init() {

		var grid_block_style = $('#grid_block_style'),
			size_width_fake = $('#size_width_fake'),
			size_width = $('#size_width');


		grid_block_style.on('change', function(){
			var grid = parseInt($(this).val());
			
			if ( grid == 13) {
				size_width_fake.val('100%');
				size_width.val('100%');			
				$('#size_width_div').addClass('is-dirty');
			} else if ( grid > 0) {
				var banner_width = grid*70+(grid-1)*30;
				size_width_fake.val(banner_width);
				size_width.val(banner_width);
				$('#size_width_div').addClass('is-dirty');
			} else {
				$('#size_width_div').removeClass('is-dirty');
				size_width_fake.val('');
				size_width.val('');
			}
		});

		$('#banner-form').on('change', '.js-banner-image-input', function (){
			var formData = new FormData();
			var banner_width = $('#size_width').val(),
				banner_height = $('#size_height').val();

			if ( !parseInt(banner_width) || !parseInt(banner_height) ) {
				swal(GLOBAL_MESSAGES.banners_width_or_height_invalid);
			} else {

				formData.append('banner_width', banner_width);
				formData.append('banner_height', banner_height);
				formData.append('file', $(this)[0].files[0]);
				var e = $(this);

				$.ajax({
		            url: $(this).attr('data-save-url'),
		            type: 'POST',
		            data:  formData,
		            cache: false,
		            contentType: false,
		            processData: false,
		            beforeSend:function(jqXHR, settings){
		            	//$('.toolbar .mdl-spinner').addClass('is-active');
		            },            
		            success: function(data, textStatus, jqXHR) {   
		            	if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
		            		e.closest('.form-data-row').find('.js-banner-image img').attr('src', data.filename_path);
		            		e.closest('.form-data-row').find('.js-banner-image-input').val(data.filename);
		            		e.closest('.form-data-row').find('.js-banner-image-oname-input').val(data.filename_orig);
		            	}                     
		            }        
		        });
			}

	    });


/*

 1170 px-es "grid törzs" 30 px-es szünetekkel 
  így az egyes bannerek horizontális X-tengelyének nagyságát ez határozná meg 
  (4/12 az 4x70 + 3x30 = 370 px 
  	vagy 5/12 az 7x70 + 6x30 = 670 px, 
  	5x70 + 4x30
  	és persze a 12/12 az 12x70 + 11x30 = 1170 px)

*/

    }

    return {

		init: init

	}

}(jQuery));