var settingModuleHelper = (function($) {

	'use strict';

    function loadGridBlockItems(e, selected) {
        $.ajax({
            url: e.attr('data-items-url'),
            type: 'POST',
            data:  {
                'grid_combo': e.val(),
                'selected': selected,
            },
            beforeSend:function(jqXHR, settings){
                //$('.toolbar .mdl-spinner').addClass('is-active');
            },            
            success: function(data, textStatus, jqXHR) {  
                var target_select = e.closest('.form-data-row').find('.js-settings-shop-mainpage-items');
                var hasComminityBlock = false;
                var hasMediaGalleryBlock = false;

                target_select.find("optgroup").remove();
                //target_select.find("option:gt(0)").remove();

                $.each(data.merged_items, function(group_title, group_items) {
                    var optgroup = $('<optgroup>');
                    optgroup.attr('label', group_title);

                    $.each(group_items, function(index, val) {
                        var option = $('<option>', {
                            value: val.id,
                            text: val.title
                        }).attr('data-image', val.image).attr('data-type', val.type);

                        if ( typeof selected !== 'undefined' && selected.length > 0 && $.inArray(val.id, selected) >= 0 ) {
                            option = option.prop('selected', true);

                            if ( val.type == 'community' ) {
                                hasComminityBlock = true;
                            }
                            if ( val.type == 'mediagallery' ) {
                                hasMediaGalleryBlock = true;
                            }
                        }

                        if ( val.inactive == 1 ) {
                            option = option.attr('data-type', val.type + '-inactive');
                        }

                        optgroup.append(option);
                    });

                    target_select.append(optgroup);
                });

                if ( hasComminityBlock ) {
                    e.closest('.form-data-row').find('.delete-column').remove();
                }
                if ( hasMediaGalleryBlock ) {
                    //e.closest('.form-data-row').find('.delete-column').remove();
                }

                target_select.select2({
                    maximumSelectionLength: data.max_items_num,
                    minimumResultsForSearch: -1,
                    placeholder: "Search...",
                    closeOnSelect: true,
                    templateSelection: select2.relatedProductStateBadgeSelection,
                    templateResult: select2.relatedProductStateBadge
                });

                target_select.on('select2:unselecting', function (e) {
                    setTimeout(function() {
                        reloadSelectOrder(selected, target_select.next('.select2').find('.ui-sortable'));
                        updateSelectOrder(target_select.next('.select2').find('.ui-sortable'));
                    }, 0);
                });

                target_select.parent().find("ul.select2-selection__rendered").sortable({
                    forcePlaceholderSize: true,
                    tolerance: 'pointer',
                    items: 'li:not(.select2-search--inline)',
                    update: function() {
                        updateSelectOrder($(this));
                    }
                });
                
                reloadSelectOrder(selected, target_select.next('.select2').find('.ui-sortable'));

                updateSelectOrder(target_select.next('.select2').find('.ui-sortable'));
            }        
        });
    }

    function initGridStaticSelects(e, selected) {
        var target_select = e.closest('.form-data-row').find('.js-settings-shop-mainpage-items');

        target_select.select2({
            maximumSelectionLength: 1000,
            minimumResultsForSearch: -1,
            placeholder: "Search...",
            closeOnSelect: true,
            templateSelection: select2.relatedProductStateBadgeSelection,
            templateResult: select2.relatedProductStateBadge
        });

        target_select.on('select2:select', function (e) {
            setTimeout(function() {
                reloadSelectOrder(selected, target_select.next('.select2').find('.ui-sortable'));
                updateSelectOrder(target_select.next('.select2').find('.ui-sortable'));
            }, 0);
        });
        target_select.on('select2:unselect', function (e) {
            setTimeout(function() {
                reloadSelectOrder(selected, target_select.next('.select2').find('.ui-sortable'));
                updateSelectOrder(target_select.next('.select2').find('.ui-sortable'));
            }, 0);
        });

        target_select.parent().find("ul.select2-selection__rendered").sortable({
            forcePlaceholderSize: true,
            tolerance: 'pointer',
            items: 'li:not(.select2-search--inline)',
            update: function() {
                updateSelectOrder($(this));
            }
        });

        reloadSelectOrder(selected, target_select.next('.select2').find('.ui-sortable'));

        updateSelectOrder(target_select.next('.select2').find('.ui-sortable'));
    }

    function reloadSelectOrder(selected, list) {
        $.each(selected, function(index, val) {
            var tempItem = '';

            tempItem = list.find('li[data-id="' + val + '"]');
            
            list.find('li[data-id="' + val + '"]').insertAfter(list.find('.select2-selection__choice').last());
        });
    }

    function updateSelectOrder(self) {
        
        var order = [];

        $.each(self.find('li.select2-selection__choice'), function(index, val) {
            order.push($(this).attr('data-id'));
        });

        self.parents('.mdl-textfield').find('.js-input-order-helper').val(order.join());
    }

    function loadCommonBlockGridItems(e) {
        $.ajax({
            url: e.attr('data-items-url'),
            type: 'POST',
            data:  {
                'grid': e.val(),
                'allowed_area': e.attr('data-allowed-area')
            },
            beforeSend:function(jqXHR, settings){
                //$('.toolbar .mdl-spinner').addClass('is-active');
            },            
            success: function(data, textStatus, jqXHR) {  
                
                var target_select = e.closest('.form-data-row').find('.js-settings-shop-common-page-banners[data-row-num=' + e.attr('data-row-num') + ']');

                target_select.find("option:gt(0)").remove();

                $.each(data.banners, function(index, val) {
                    var option = $('<option>', {
                        value: val.id,
                        text: val.title
                    })/*.attr('data-image', val.image)*/.attr('data-type', 'banner');

                    /*if ( val.inactive == 1 ) {
                        option = option.attr('data-type', val.type + '-inactive');
                    }*/

                    target_select.append(option);
                });

                target_select.select2({     
                    maximumSelectionLength: data.max_items_num,
                    minimumResultsForSearch: -1,
                    placeholder: "Search...",
                    closeOnSelect: true,
                    templateSelection: select2.relatedProductStateBadgeSelection,
                    templateResult: select2.relatedProductStateBadge
                });

                target_select.parent().find("ul.select2-selection__rendered").sortable({
                    forcePlaceholderSize: true,
                    tolerance   : 'pointer',
                    items: 'li:not(.select2-search--inline)',
                    update: function() {
                        select2.select2OrderSortedValues($(this));
                    }
                });  
                              
            }        
        });
    }

	function init() {

        /**
         *  Settings / Shop / Grid combo változtatás
         */
        $('.js-ajax-post-form').on('change', '.js-settings-shop-mainpage-grid', function (){
            var e = $(this);

            loadGridBlockItems(e);
        });


        /**
         *  Settings / Shop / Grid Items változtatás
         */
        $('.js-ajax-post-form').on('change', '.js-settings-shop-mainpage-items', function (){
            var e = $(this);

            var hasCommunityBlock = false;
            var hasMediaGalleryBlock = false;
            var gridItemsNum = 0;

            $.each(e.find('option:selected'), function(index, option) {
                if ( $(option).val() == 'community_-1' || $(option).val() == 'community_-2' ) {
                    hasCommunityBlock = true;
                }
                if ( $(option).val().startsWith("mediagallery_") ) {
                    hasMediaGalleryBlock = true;
                }
                gridItemsNum++;
            });

            if ( hasCommunityBlock === true && gridItemsNum >= 2 ) {
                e.find("option:selected").last().prop('selected', false);
                e.change();

                swal(GLOBAL_MESSAGES.settings_grid_community_only_one_item);
            }
            if ( hasMediaGalleryBlock === true && gridItemsNum >= 2 ) {
                e.find("option:selected").last().prop('selected', false);
                e.change();

                swal(GLOBAL_MESSAGES.settings_grid_mediagallery_only_one_item);
            }
        });

        $('.mdl-switch--condition').on('change', function() {

            console.log($(this).attr('data-target-container'));

            if($(this).is(":checked")) {
                $(this).parents('.mdl-grid').next('[data-switch-container="' + $(this).attr('data-target-container') + '"]').removeAttr('hidden');
            } else {
                $(this).parents('.mdl-grid').next('[data-switch-container="' + $(this).attr('data-target-container') + '"]').attr('hidden', '');
            }
        });

        /**
         *  Settings / Shop / Grid combo változtatás
         */
        $('.js-ajax-post-form').on('change', '.js-settings-shop-common-page-grid', function (){
            var el = $(this);

            loadCommonBlockGridItems(el, []);
        });
        
        /**
         *  Bannerek kezelésénél sor ürítése
         */
        $('.js-ajax-post-form').on('click', '.js-banners-common-blocks-empty-row', function (){
            var row = $(this).closest('.form-data-row');

            row.find('select[data-row-num=' + $(this).attr('data-row-num') + ']').val('').trigger("change");
            row.find('input[type=checkbox][data-row-num=' + $(this).attr('data-row-num') + ']').prop('checked', false).parent().removeClass('is-checked');
        });

    }

    return {

        init: init,
		loadGridBlockItems: loadGridBlockItems,
        initGridStaticSelects: initGridStaticSelects,

	}

}(jQuery));