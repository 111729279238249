var simpleAccordion = (function($) {

    'use strict';

    function init() {
        console.log('accordion init......');
        $('body').off('click.simple-accordion');
        $('body').on('click.simple-accordion', '.simple-accordion__title', function() {
            console.log('accordion title init.....');
            $(this).parent('.simple-accordion').toggleClass('is-open');
        });

    }

    return {

        init: init

    }

}(jQuery));