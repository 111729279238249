
var magnific = (function($) {

    'use strict';

    function init() {

        $('.js-magnific-ajax-popup').magnificPopup({
            type: 'ajax',
            fixedContentPos: true
        });

        $('.js-magnific-ajax-popup-wide').magnificPopup({
            type: 'ajax',
            fixedContentPos: true,
            mainClass: 'mfp-content-wide',
        });

        $('body').on('click', '.js-magnific-ajax-popup-close', function() {
            magnific.close();
        });

        $('body').magnificPopup({
            delegate: '.js-product-gallery-link',
            type: 'image',
            mainClass: 'image-gallery',
            gallery: {
                enabled: true
            }
        });

    }

    function close() {
        var magnificPopup = $.magnificPopup.instance; 
        magnificPopup.close(); 
    }

    return {

        init: init,
        close: close

    }

}(jQuery));
