
var pfScrollbar = (function($) {

    'use strict'; 

    var mainContainer = document.getElementById('js-main-container'),
        sidenav = document.getElementById('js-sidenav'),
        searchWrapper = document.getElementById('js-search-result-wrapper'),
        scrollToTop = $('.scroll-to-top-btn');


    function _searchWrapperInit() {
        // Ps.destroy(searchWrapper);
        // Ps.initialize(searchWrapper, {
        //   wheelSpeed: 1,
        //   wheelPropagation: true,
        //   minScrollbarLength: 20
        // });
    }

    
    function _mainContainerInit(reset) {       

        if (!CSS.supports('position', 'sticky')) {

            $('.inline-header-wrapper').css({
                'top': 0
            });

            if($('#js-main-container .page-header-wrapper').length > 0) {
                $('#js-main-container .page-header-wrapper').css({
                    'margin-top': $('.inline-header-wrapper').height() + 48
                });
            } else {
                $('#js-main-container .page-header').css({
                    'margin-top': $('.inline-header-wrapper').height() + 48
                });
            }
            
            if(reset !== false && $('.mfp-bg.mfp-ready').length === 0) {
                mainContainer.scrollTop = 0;
            }

            // setTimeout(function() {

            //     Ps.destroy(mainContainer);
            //     Ps.initialize(mainContainer, {
            //       wheelSpeed: 2,
            //       wheelPropagation: true,
            //       minScrollbarLength: 20
            //     });

            // }, 300);

            mainContainer.addEventListener('ps-scroll-y', function (e) {
                $('.inline-header-wrapper').css({
                    'top': Math.floor($(e.target).scrollTop())
                });
            });
        } else {
            if(reset !== false && $('.mfp-bg.mfp-ready').length === 0) {
                mainContainer.scrollTop = 0;
            }

            $(mainContainer).find('.inline-header-wrapper').addClass('custom-test-class');

            // setTimeout(function() {
                
            //     Ps.destroy(mainContainer);
            //     Ps.initialize(mainContainer, {
            //       wheelSpeed: 2,
            //       wheelPropagation: true,
            //       minScrollbarLength: 20
            //     });

            // }, 300);
        }
    }

    function _tableInit() {
        // var scrollItem = document.querySelector('.table-overflow-wrapper');
        // if(document.body.contains(scrollItem)) {
        //     Ps.destroy(scrollItem);
        //     Ps.initialize(scrollItem, {
        //       wheelSpeed: 2,
        //       wheelPropagation: true,
        //       minScrollbarLength: 20
        //     });
        // }
    }
    
    function _sidenavInit() {
        // Ps.destroy(sidenav);
        // Ps.initialize(sidenav, {
        //   wheelSpeed: 2,
        //   wheelPropagation: true,
        //   minScrollbarLength: 20
        // });
    }


    function _scrollToTopInit() {
        scrollToTop.off();

        scrollToTop.on('click', function() {           

            $('.inline-header-wrapper').css({
                'top': 0
            });

            $(mainContainer).dequeue().stop().animate({						
                'scrollTop': 0
            }, 800);

            // mainContainer.scrollTop = 0;
            // Ps.update(mainContainer);
        });

        $(mainContainer).on('scroll', function() {
            if($(this).scrollTop() > 400) {
                scrollToTop.addClass('is-visible');
            } else {
                scrollToTop.removeClass('is-visible');
            }
        }); 
    }

    function init(reset) {

        _searchWrapperInit();
        _mainContainerInit(reset);
        _sidenavInit();
        _scrollToTopInit();
        _tableInit();

    }


    $(window).resize(function() {
        _mainContainerInit();
    });

    return {

        init: init,
        searchWrapperInit: _searchWrapperInit,
        mainContainerInit: _mainContainerInit,
        sidenavInit: _sidenavInit,
        scrollToTopInit: _scrollToTopInit,
        tableInit: _tableInit

    }

}(jQuery));