
var dropzone = (function($) {

    'use strict';

    function init() {

        Dropzone.autoDiscover = false;

        $("div#js-dropzone-keymanager-import").dropzone({
            dictDefaultMessage: GLOBAL_MESSAGES.global_dropzone_default_message,
            url: '/',
            addRemoveLinks: true,
            acceptedFiles: "text/plain",
            maxFiles: 1,
            dictDefaultMessage: '',
            init: function() {
                this.options.url = this.element.dataset.url;
            },
            success: function( file, response ){
                if ( response.filename == '' ) {
                    this.removeFile(file);
                    swal({
                        title: "{!! _('A fájl nem megfelelő!') !!}",
                        text: "{!! _('Elfogadott formátumok: txt.<br />Maximális fájlméret: 10 MB.') !!}",
                        type: "error",
                        html: true
                    });
                } else {
                    var append_file = '<input type="hidden" name="file" value="'+ response.filename +'"><input type="hidden" name="file_name" value="'+ response.filename_orig +'">';
                    $('.uploadad-file').html(append_file);
                }
            }
        });

/*
        $("div.js-dropzone-single").dropzone({
            dictDefaultMessage: GLOBAL_MESSAGES.global_dropzone_default_message,
            url: '/',
            addRemoveLinks: true,
            acceptedFiles: "image/*",
            maxFiles: 1,
            init: function() {
                this.options.url = this.element.dataset.url;
                this.options.acceptedFiles = this.element.dataset.filetypes;
            },
            success: function( file, response ){
                if ( response.filename == '' ) {
                        this.removeFile(file);
                        swal({
                            title: "{!! _('A kép nem megfelelő!') !!}",
                            text: "{!! _('Elfogadott formátumok: jpeg, jpg, png, gif.<br />Maximális fájlméret: 10 MB.') !!}",
                            type: "error",
                            html: true
                    });
                } else {
                   
                        var append_file = '<input type="hidden" name="more_images[]" value="'+ response.filename +'"><input type="hidden" name="more_images_names[]" value="'+ response.filename_orig +'">';
                        $('.uploadad-file').html(append_file);
                        this.removeFile(file);




                }

            }
        });
*/


    }

    return {

        init: init

    }

}(jQuery));