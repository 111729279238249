var productModuleHelper = (function($) {

	'use strict';

    function setPriceUsdPercent() {
    	var _price = parseFloat($('#price_usd').val());
    	var _price_sales = parseFloat($('#sale_price_usd').val());
    	var _percent = '-';

    	if	( _price > 0 && _price_sales > 0 && _price > _price_sales ) {
    		_percent = parseInt((1 - (_price_sales / _price)) * 100);
            _percent = '- '+_percent+'%'
		}

        $('#sale_price_percent').val(_percent);
    }

	function init() {

		var productAvilabilityInput = $("#product-availability"),
			productAvilabilityQuantityBlock = $("#product-availability-quantity"),
			productAvilabilityDateBlock = $("#product-availability-date"),
			productAvatarInput = $("#user-avatar-input"),
			productAvatarImage = $('#user-avatar'),
			productAvatarUploadIcon = $('.page-header-user__avatar-icon'),
			productAvatarWrapper = $('.page-header-user__avatar-image-wrapper'),
			productBackgroundInput = $("#user-background-input"),
			productBackgroundImage = $('.page-header--user-profile'),
			productEditIcon = $('.page-header-user__name-edit'),
			productName = $('.page-header-user__name'),
			highlightVideoField = $('.js-highlight-video-field'),
			productNameField = $('.js-product-title'),
			snippetInputTitle = $('.snippet-preview-title'),
			snippetInputDesc = $('.snippet-preview-description'),
			snippetInputUrl = $('.snippet-preview-friendly-url'),
			snippetPreviewTitle = $('.google-preview__title'),
			snippetPreviewDesc = $('.google-preview__description'),
			snippetPreviewUrl = $('.google-preview__url-custom'),
			productPriceMatrixOneOnlyButton = $('.js-product-matrix-save-single'),
			productPriceMatrixOneOnlyTarget = $('#js-price-matrix-one-only'),
			productMediaImagesCreateGallery = $('#js-media-product-images-create-gallery'),
			productMediaImagesNewGalleryName = $('#media_product_images_new_gallery'),
			productMediaImagesGallerySelect = $('#js-media-product-images-galleries'),
			productMediaImagesGallerySelectOptionsSelected = $('#js-media-product-images-galleries option:selected'),
			productMediaImagesSelectAll = $('#js-media-product-images-select-all'),
			productMediaImagesRemove = $('#js-media-product-images-remove'),
			productMediaImagesDelete = $('#js-media-product-images-delete'),

			productMediaGalleryImagesCreateGallery = $('#js-media-product-gallery-images-create-gallery'),
			productMediaGalleryImagesNewGalleryName = $('#media_product_gallery_images_new_gallery'),
			productMediaGalleryImagesGallerySelect = $('#js-media-product-gallery-images-galleries'),
			productMediaGalleryImagesGallerySelectOptionsSelected = $('#js-media-product-gallery-images-galleries option:selected'),
			productMediaGalleryImagesSelectAll = $('#js-media-product-gallery-images-select-all'),
			productMediaGalleryImagesRemove = $('#js-media-product-gallery-images-remove'),
			productMediaGalleryImagesDelete = $('#js-media-product-gallery-images-delete'),

			//productMediaVideosCreateGallery = $('.js-media-product-videos-create-gallery'),
			//productMediaVideosNewGalleryName = $('.media_product_videos_new_gallery'),
			//productMediaVideosGallerySelect = $('#js-media-product-videos-galleries'),
			//productMediaVideosGallerySelectOptionsSelected = $('#js-media-product-videos-galleries option:selected'),

			productMediaVideosCoverInput = $('.js-media-video-cover-input'),

			productMediaUploadToggle = $('.mdl-content-header__upload-btn'),

			productSalesAccept = $('.js-sales-selected-products-accept');

		var delay = (function(){
		  var timer = 0;
		  return function(callback, ms){
		    clearTimeout (timer);
		    timer = setTimeout(callback, ms);
		  };
		})();

        initSales();

        setPriceUsdPercent();
        $('body').on('keyup', '#price_usd', function() {
            setPriceUsdPercent();
        });
        $('body').on('keyup', '#sale_price_usd', function() {
            setPriceUsdPercent();
        });

		$('#checkbox-upgrade_edition').on('click', function() {
			if( $(this).prop('checked') ) {
				$('#tier_pack-2').click();
				$('#js-tier-selector-container').hide();
				$('.js-upgrade-from-container').show();
				$('.js-upgrade-pack-name-container').show();
			} else {
				$('#js-tier-selector-container').show();
				$('.js-upgrade-from-container').hide();
				$('.js-upgrade-pack-name-container').hide();
			}
		});

		$('.js-tier-package-checkbox input[type="radio"]').on('change', function() {
			if($(this).val() == '1') {
				$('.js-tier-fields-container').show();
				$('.js-next-tier-container').show();
			} else {
				$('.js-tier-fields-container').hide();
				$('.js-next-tier-container').hide();
			}
		});

		$('.js-releated-products-checkbox input[type="radio"]').on('change', function() {
			if($(this).val() == 'manual') {
				$('.related-products').show();

				$("#specific-related-product-select").select2("open");
			} else {
				$('.related-products').hide();
			}
		});

		$('.related-product__add-new').on('click', function() {
			$("#specific-related-product-select").select2("open");
		});

		// Header Selects

		$('.js-header-open-select').on('click', function() {
			$(this).parent('.page-header-generic__meta').find('select').select2('open');
		});

		$('.js-select2-simple--header').on('select2:select', function (e) {
			var data = $(this).select2('data');
			$(this).parents('.page-header-generic__meta').find('.page-header-generic__meta-text').text(data[0].text);

			$($(this).attr('data-connected-select')).val(data[0].id).trigger('change.select2');
		});

		$('.mdl-tabs__panel').on('select2:select', '.js-select2-simple--modify-header', function (e) {
			var data = $(this).select2('data');
			$($(this).attr('data-connected-select')).parents('.page-header-generic__meta').find('.page-header-generic__meta-text').text(data[0].text);

			$($(this).attr('data-connected-select')).val(data[0].id).trigger('change.select2');
		});

        function _getYouTubeUrl(url) {
			var videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
			if(videoid != null) {
				return {
					valid: true,
					id: videoid[1]
				};

				// return videoid[1];
			} else { 
				return false;
			}
		}

		function _productMediaImagesRemove(images, delete_image, delete_url) {
			$.each(images, function(index, image) {
				$('div.js-media-product-images-image-block[data-image-id="'+image+'"]').remove();

			});

			if ( delete_image == true ) {
			    $.ajax({
			        url: delete_url,
			        type: 'POST',
			        data: {
			        	images: images
			        },
			        success: function(data, textStatus, xhr) {
			        },
			        error: function(xhr, status, error) {
			        }
			    });
			}	    
		}

		function _productMediaGalleryImagesRemove(images, delete_image, delete_url) {
			$.each(images, function(index, image) {
				$('div.js-media-product-gallery-images-image-block[data-image-id="'+image+'"]').remove();

			});

			if ( delete_image == true ) {
			    $.ajax({
			        url: delete_url,
			        type: 'POST',
			        data: {
			        	images: images
			        },
			        success: function(data, textStatus, xhr) {
			        },
			        error: function(xhr, status, error) {
			        }
			    });
			}	    
		}

		/**
		 *	Elérhetőség változtatásakor a további mezők megjelenítése
		 */
		productAvilabilityInput.on('change', function (){

			switch ($(this).val()) {
			    case 'limited':
			        productAvilabilityQuantityBlock.show();
			        productAvilabilityDateBlock.hide();
			        break;
			    case 'limited_time':
			        productAvilabilityQuantityBlock.hide();
			        productAvilabilityDateBlock.show();
			        break;
			    case 'mbv':
			        productAvilabilityQuantityBlock.show();
			        productAvilabilityDateBlock.hide();
			        break;
			    default:
			        productAvilabilityQuantityBlock.hide();
			        productAvilabilityDateBlock.hide();
			        break;
			} 
	    });


		/**
		 *	Termék avatar feltöltésének, behelyettesítésének a lekezelése.
		 */
		productAvatarInput.on('change', function (){
	        var img = productAvatarImage;
	        var file = this.files[0];

	        var reader = new FileReader();
	        reader.onload = function (e) {
	            img.attr('src', e.target.result);
	            productAvatarUploadIcon.hide();

	        };
	        reader.readAsDataURL(file);

	        if(file.size/1024 > 500) {
	        	productAvatarWrapper.addClass('is-error')
	        } else {
	        	productAvatarWrapper.removeClass('is-error');
	        }
	    });

	   	/**
		 *	Termék háttér feltöltésének, behelyettesítésének a lekezelése.
		 */
		productBackgroundInput.on('change', function (){
	        var img = productBackgroundImage;
	        var file = this.files[0];

	        var reader = new FileReader();
	        reader.onload = function (e) {
	            img.css({
	            	'background-image': 'url(' + e.target.result + ')'
	            });
	        };
	        reader.readAsDataURL(file);
	    });


	    /**
		 *	Fejlécben található termék név szerkesztése.
		 */
		
		function _setCarretPosition(selector) {
			var node = document.querySelector('.' + selector + ' span'),
				textNode = node.firstChild,
				caret = textNode.length,
				range = document.createRange(),
			 	sel = window.getSelection();


			node.focus();
			
			range.setStart(textNode, caret);
			range.setEnd(textNode, caret);

			sel.removeAllRanges();
			sel.addRange(range);
		}

		$('.page-header-generic__text-edit-icon').on('click', function() {

			$(this).parents('.editable-content').find('span').attr('contenteditable', 'true')

			_setCarretPosition($(this).parents('.editable-content').attr('class').split(' ')[0]);

			$(this).parents('.editable-content').find('span').keypress(function(e){ return e.which != 13; });
		});

		$('.js-editable-content').on('input', function() {

			var inputItem = $($(this).attr('data-connected-field'))

	    	inputItem.parents('.mdl-textfield').addClass('is-focused');

	    	var updateString = String($(this).text().trim());
/*
	    	if($($(this).attr('data-connected-field')) == 'true') {
	    		_updateUrl(updateString);
	    	}
*/
			setTimeout(function() {
				inputItem.val(updateString);
			}, 400);
	    });


	    /**
		 *	Űrlapon található felhazsnálói név szerkesztése.
		 */
	    $('body').on('keyup', '.js-update-editable-content', function() {
	    	var _self = $(this),
	    		updatedField = $($(this).attr('data-connected-field'));
	    	setTimeout(function() {
	    		var updateString = String(_self.val().trim());
				updatedField.text(updateString);

				// _updateUrl(updateString);
			}, 400);	
	    });


	    highlightVideoField.on('keypress change', function() {
	    	var result = _getYouTubeUrl($(this).val());

	    	if(result.valid) {
	    		$(this).parents('.mdl-cell').next('.mdl-cell').find('.js-youtube-preview').attr('src', 'https://www.youtube.com/embed/' + result.id);
	    		$(this).siblings('.mdl-textfield__error').css({ 'visibility': 'hidden' });
	    	} else {
	    		$(this).siblings('.mdl-textfield__error').css({ 'visibility': 'visible' });
	    		$(this).parents('.mdl-cell').next('.mdl-cell').find('.js-youtube-preview').attr('src', '');
	    	}
	    });


	    /**
	     * Snippet Preview 
	     */

		snippetInputTitle.on('keyup', function() {
			snippetPreviewTitle.text($(this).val());
		});

		snippetInputDesc.on('keyup', function() {
			snippetPreviewDesc.text($(this).val());
		});

		snippetInputUrl.on('keyup', function() {
			snippetPreviewUrl.text(slugify($(this).val()));
		});

		function _updateUrl(updateString) {
			if(snippetInputUrl.val().length === 0) {
				snippetPreviewUrl.text(slugify(updateString));
			}
		}

		productPriceMatrixOneOnlyButton.on('click', function() {
			productPriceMatrixOneOnlyTarget.val( $(this).attr('data-id') );

			$(this).closest('form').submit();
		});


		// Match Height
		
		$('.mdl-tabs__tab').on('click', function() {

			$('[data-mh="equal-price-box"]').matchHeight({
			    byRow: false,
			    property: 'height',
			    target: null,
			    remove: false
			});

            postfixerGlobalInit();
		});

		$('.snippet-preview-friendly-url').on('keypress', function(e) {
			var regex = new RegExp("^[a-zA-Z0-9-/]+$");
		    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
		    if (regex.test(str)) {
		        return true;
		    }

		    e.preventDefault();
		    return false;
		});

		/**
		 * Media
		 */
        Dropzone.autoDiscover = false;

        // Products / Product Images / Dropzone init
        var mediaProductImagesDropzone = $("div#js-dropzone-media-product-images").dropzone({
            dictDefaultMessage: GLOBAL_MESSAGES.global_dropzone_default_message,
            url: '/',
            addRemoveLinks: true,
            acceptedFiles: "image/jpeg,image/png,image/gif",
            maxFiles: 30,
            dictDefaultMessage: '',
            init: function() {
                this.options.url = this.element.dataset.url;
            },
            success: function( file, response ){
                if ( response.filename == '' ) {
                    this.removeFile(file);
                } else {
                    $('#js-media-product-images-container').append(response);

                	this.removeFile(file);
                }
            }
        });  


        productMediaUploadToggle.on('click', function(e) {
        	e.preventDefault();
        	$(this).toggleClass('is-active');
        	$(this).parents('.mdl-content-header').next('.mdl-content').find('.gallery-dropzone').toggleClass('is-active');
        });   


        // Products / Product Images / Select galleries
		productMediaImagesGallerySelect.on('change', function() {
			var galleries_selected = productMediaImagesGallerySelect.val(); 

		    $.ajax({
		        url: $(this).attr('data-images-url'),
		        type: 'POST',
		        data: {
		        	from: 'productsImages',
		        	galleries: galleries_selected
		        },
		        success: function(data, textStatus, xhr) {
		        	$.each(data.images, function(index, val) {
		        		if ( $('div.js-media-product-images-image-block[data-image-id="'+val.id+'"]').length == 0 ) {
		        			$('#js-media-product-images-container').append(val.template);
		        		}
		        	});
		        },
		        error: function(xhr, status, error) {
		        	var response = $.parseJSON(xhr.responseText);
					sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
		        }
		    });
		});

        // Products / Product Images / Create gallery
		productMediaImagesCreateGallery.on('click', function() {
			var name = productMediaImagesNewGalleryName.val();
			if ( name == '' ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_name_empty, "error");
	            return false;			
			}

			var images = $("input[name^='product_images\[']").map(function(){return $(this).val();}).get();
			if ( images.length == 0 ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_images_empty, "error");
	            return false;			
			}

		    $.ajax({
		        url: $(this).attr('data-save-url'),
		        type: 'POST',
		        data: {
		        	type: 'image',
		        	name: name,
		        	images: images
		        },
		        success: function(data, textStatus, xhr) {
		        	productMediaImagesGallerySelect.append($('<option>', {
					    value: data.id,
					    text: data.name
					}));
					productMediaImagesGallerySelectOptionsSelected.prop("selected", false);
					productMediaImagesGallerySelect.val([data.id]);

					productMediaImagesNewGalleryName.val('');
		        },
		        error: function(xhr, status, error) {
		        	var response = $.parseJSON(xhr.responseText);
					sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
		        }
		    });

			return false;
		});

        // Products / Product Images / Select All
		productMediaImagesSelectAll.on('click', function() {
			$(this).toggleClass('is-active');

			if ( $(this).hasClass('all') ) {
				$('#js-media-product-images-container .js-media-product-images-image-block input[type="checkbox"]').prop('checked', false);
				$(this).removeClass('all')
			} else {
				$('#js-media-product-images-container .js-media-product-images-image-block input[type="checkbox"]').prop('checked', true);
				$(this).addClass('all')
			}
			return false;
		});

        // Products / Product Images / Remove
		productMediaImagesRemove.on('click', function() {
			var images = $('#js-media-product-images-container .js-media-product-images-image-block input[type="checkbox"]:checked').map(function(){
				return $(this).closest('.js-media-product-images-image-block').attr('data-image-id');
			}).get();

			if ( images.length == 0 ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_images_not_selected, "error");
	            return false;			
			}

            swal({
              title: GLOBAL_MESSAGES.global_are_you_sure,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#F44336",
              confirmButtonText: GLOBAL_MESSAGES.global_yes_do_it,
              closeOnConfirm: true
            },
            function(){
                _productMediaImagesRemove(images, false, '');             
            });

			return false;
		});
        // Products / Product Images / Delete
		productMediaImagesDelete.on('click', function() {
			var images = $('#js-media-product-images-container .js-media-product-images-image-block input[type="checkbox"]:checked').map(function(){
				return $(this).closest('.js-media-product-images-image-block').attr('data-image-id');
			}).get();

			if ( images.length == 0 ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_images_not_selected, "error");
	            return false;			
			}

            swal({
              title: GLOBAL_MESSAGES.global_are_you_sure,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#F44336",
              confirmButtonText: GLOBAL_MESSAGES.global_yes_do_it,
              closeOnConfirm: true
            },
            function(){
				_productMediaImagesRemove(images, true, productMediaImagesDelete.attr('data-url'));
            });

			return false;
		});





        // Products / Gallery Images / Dropzone init
        var mediaProductImagesDropzone = $("div#js-dropzone-media-product-gallery-images").dropzone({
            dictDefaultMessage: GLOBAL_MESSAGES.global_dropzone_default_message,
            url: '/',
            addRemoveLinks: true,
            acceptedFiles: "image/jpeg,image/png,image/gif",
            maxFiles: 30,
            dictDefaultMessage: '',
            init: function() {
                this.options.url = this.element.dataset.url;
            },
            success: function( file, response ){
                if ( response.filename == '' ) {
                    this.removeFile(file);
                } else {
                    $('#js-media-product-gallery-images-container').append(response);

                	this.removeFile(file);
                }
            }
        });  

		// Products / Gallery Images / Select galleries
		productMediaGalleryImagesGallerySelect.on('change', function() {
			var galleries_selected = productMediaGalleryImagesGallerySelect.val(); 

		    $.ajax({
		        url: $(this).attr('data-images-url'),
		        type: 'POST',
		        data: {
		        	from: 'productsImages',
		        	galleries: galleries_selected
		        },
		        success: function(data, textStatus, xhr) {
		        	$.each(data.images, function(index, val) {
		        		if ( $('div.js-media-product-gallery-images-image-block[data-image-id="'+val.id+'"]').length == 0 ) {
		        			$('#js-media-product-gallery-images-container').append(val.template);
		        		}
		        	});
		        },
		        error: function(xhr, status, error) {
		        	var response = $.parseJSON(xhr.responseText);
					sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
		        }
		    });
		});

        // Products / Gallery Images / Create gallery
		productMediaGalleryImagesCreateGallery.on('click', function() {
			var name = productMediaGalleryImagesNewGalleryName.val();
			if ( name == '' ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_name_empty, "error");
	            return false;			
			}

			var images = $("input[name^='product_gallery_images\[']").map(function(){return $(this).val();}).get();
			if ( images.length == 0 ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_images_empty, "error");
	            return false;			
			}

		    $.ajax({
		        url: $(this).attr('data-save-url'),
		        type: 'POST',
		        data: {
		        	type: 'image',
		        	name: name,
		        	images: images
		        },
		        success: function(data, textStatus, xhr) {
		        	productMediaGalleryImagesGallerySelect.append($('<option>', {
					    value: data.id,
					    text: data.name
					}));
					productMediaGalleryImagesGallerySelectOptionsSelected.prop("selected", false);
					productMediaGalleryImagesGallerySelect.val([data.id]);

					productMediaGalleryImagesNewGalleryName.val('');
		        },
		        error: function(xhr, status, error) {
		        	var response = $.parseJSON(xhr.responseText);
					sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
		        }
		    });

			return false;
		});

        // Products / Gallery Images / Select All
		productMediaGalleryImagesSelectAll.on('click', function() {
			$(this).toggleClass('is-active');

			if ( $(this).hasClass('all') ) {
				$('#js-media-product-gallery-images-container .js-media-product-gallery-images-image-block input[type="checkbox"]').prop('checked', false);
				$(this).removeClass('all')
			} else {
				$('#js-media-product-gallery-images-container .js-media-product-gallery-images-image-block input[type="checkbox"]').prop('checked', true);
				$(this).addClass('all')
			}
			return false;
		});

        // Products / Gallery Images / Remove
		productMediaGalleryImagesRemove.on('click', function() {
			var images = $('#js-media-product-gallery-images-container .js-media-product-gallery-images-image-block input[type="checkbox"]:checked').map(function(){
				return $(this).closest('.js-media-product-gallery-images-image-block').attr('data-image-id');
			}).get();

			if ( images.length == 0 ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_images_not_selected, "error");
	            return false;			
			}

            swal({
              title: GLOBAL_MESSAGES.global_are_you_sure,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#F44336",
              confirmButtonText: GLOBAL_MESSAGES.global_yes_do_it,
              closeOnConfirm: true
            },
            function(){
                _productMediaGalleryImagesRemove(images, false, '');             
            });

			return false;
		});
        // Products / Gallery Images / Delete
		productMediaGalleryImagesDelete.on('click', function() {
			var images = $('#js-media-product-gallery-images-container .js-media-product-gallery-images-image-block input[type="checkbox"]:checked').map(function(){
				return $(this).closest('.js-media-product-gallery-images-image-block').attr('data-image-id');
			}).get();

			if ( images.length == 0 ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_images_not_selected, "error");
	            return false;			
			}

            swal({
              title: GLOBAL_MESSAGES.global_are_you_sure,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#F44336",
              confirmButtonText: GLOBAL_MESSAGES.global_yes_do_it,
              closeOnConfirm: true
            },
            function(){
				_productMediaGalleryImagesRemove(images, true, productMediaGalleryImagesDelete.attr('data-url'));
            });

			return false;
		});






        // Products / Video Gallery / Select galleries
		$('.js-media-product-videos-galleries').on('change', function() {
			var galleries_selected = $(this).val();
			var element = $(this);

		    $.ajax({
		        url: $(this).attr('data-images-url'),
		        type: 'POST',
		        data: {
		        	from: 'productsImages',
		        	galleries: galleries_selected
		        },
		        success: function(data, textStatus, xhr) {
		        	$.each(data.videos, function(index, val) {
		        		if ( element.closest('.js-block-item').find('div.js-media-product-videos-video-block[data-video-id="'+val.id+'"]').length == 0 ) {
    						$(val.template).insertBefore( element.closest('.js-block-item').find('.display-helper') );
		        		}
		        	});

					uiHelper.saveDgOrderingList(element.closest('.js-block-item').find('.js-form-data-container'));

					window['colorPickerGlobalInit']();
					window['perfectScrollbarGlobalInit'](false);
					window['mdlGlobalInit']();
					select2.settingsBlockInit();
		        },
		        error: function(xhr, status, error) {
		        	var response = $.parseJSON(xhr.responseText);
					sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
		        }
		    });
		});

        // Products / Video Gallery / Create gallery
		$('.js-media-product-videos-create-gallery').on('click', function() {
			var element = $(this);
			var name = $(this).closest('.js-block-item').find('.media_product_videos_new_gallery').val();
			var lang = $(this).closest('.js-block-item').attr('data-lang');
			if ( name == '' ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_name_empty, "error");
	            return false;			
			}

			var videos = $(this).closest('.js-block-item').find("input[name^='media_videos_url\["+lang+"\]']:not(:disabled)").map(function(){return $(this).val();}).get();
			if ( videos.length == 0 ) {
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_images_gallery_videos_empty, "error");
	            return false;			
			}
			var titles = $(this).closest('.js-block-item').find("input[name^='media_videos_title\["+lang+"\]']:not(:disabled)").map(function(){return $(this).val();}).get();
			var covers = $(this).closest('.js-block-item').find("input[name^='media_videos_cover_uploaded_image\["+lang+"\]']:not(:disabled)").map(function(){return $(this).val();}).get();
			var video_ids = $(this).closest('.js-block-item').find("input[name^='media_videos_id\["+lang+"\]']:not(:disabled)").map(function(){return $(this).val();}).get();

		    $.ajax({
		        url: $(this).attr('data-save-url'),
		        type: 'POST',
		        data: {
		        	type: 'video',
		        	name: name,
		        	videos: videos,
		        	titles: titles,
		        	covers: covers,
		        	ids: video_ids,
		        	lang: lang
		        },
		        success: function(data, textStatus, xhr) {

		        	element.closest('.js-block-item').find('.js-media-product-videos-galleries').append($('<option>', {
					    value: data.id,
					    text: data.name
					}));
					element.closest('.js-block-item').find('.js-media-product-videos-galleries option:selected').prop("selected", false);
					element.closest('.js-block-item').find('.js-media-product-videos-galleries').val([data.id]);

					element.closest('.js-block-item').find('.media_product_videos_new_gallery').val('');

					// Frissen feltöltött videókhoz ID beállítás
					if ( jQuery.isEmptyObject(data.video_ids) == false ) {
						$.each(data.video_ids, function(index, val) {
							var _url_field = element.closest('.js-block-item').find("input[name^='media_videos_url\["+lang+"\]']").filter(function(){return this.value == index});
							var _id_field  = _url_field.closest('.form-data-row').find("input[name^='media_videos_id\["+lang+"\]']");

							if ( _id_field.val() == '' ) {
								_id_field.val(val);
							}
						});
					}
		        },
		        error: function(xhr, status, error) {
		        	var response = $.parseJSON(xhr.responseText);
					sweetAlert(GLOBAL_MESSAGES.global_oops, response.message, "error");
		        }
		    });

			return false;
		});

		/**
		 *	Videó cover fotó feltöltés
		 */
		$('#media-panel').on('change', '.js-media-video-cover-input', function (){
			var formData = new FormData();
			formData.append('file', $(this)[0].files[0]);
			var e = $(this);

			$.ajax({
	            url: $(this).attr('data-save-url'),
	            type: 'POST',
	            data:  formData,
	            cache: false,
	            contentType: false,
	            processData: false,
	            beforeSend:function(jqXHR, settings){
	            	//$('.toolbar .mdl-spinner').addClass('is-active');
	            },            
	            success: function(data, textStatus, jqXHR) {   
	            	if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
	            		e.closest('.form-data-row').find('.js-media-product-videos-image img').attr('src', data.filename_path);
	            		e.closest('.form-data-row').find('.js-media-product-videos-image-input').val(data.filename);
	            		e.closest('.form-data-row').find('.js-media-product-videos-image-oname-input').val(data.filename_orig);
	            	}                     
	            }        
	        });
	    });

		/**
		 *	Youtube videó behúzás
		 */
		$('#media-panel').on('click', '.js-media-product-videos-url-load', function (){
			var youtube_url = $(this).closest('.form-data-row').find('.js-media-product-videos-url-input').val();
	    	var youtube_parsed = _getYouTubeUrl(youtube_url);
			var e = $(this);

			if ( youtube_parsed === false ) {
				e.closest('.form-data-row').find('.js-media-product-videos-play').hide();
				sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_youtube_url_invalid, "error");
	            return false;			
			}

			$.ajax({
	            url: $(this).attr('data-save-url'),
	            type: 'POST',
	            data:  {
	            	youtube_id: youtube_parsed.id
	            },           
	            success: function(data, textStatus, jqXHR) {

					if ( typeof data.valid !== 'undefined' && data.valid == false ) {
						e.closest('.form-data-row').find('.js-media-product-videos-play').hide();
						sweetAlert(GLOBAL_MESSAGES.global_oops, GLOBAL_MESSAGES.products_youtube_id_invalid, "error");
			            return false;			
					}
	            	if ( typeof data.filename !== 'undefined' && data.filename != '' ) {
	            		e.closest('.form-data-row').find('.js-media-product-videos-image img').attr('src', data.filename_path);
	            		e.closest('.form-data-row').find('.js-media-product-videos-image-input').val(data.filename);
	            		e.closest('.form-data-row').find('.js-media-product-videos-image-oname-input').val(data.filename_orig);

	            		e.addClass('checked');
	            		e.closest('.form-data-row').find('.js-media-product-videos-play').show();

	            		e.removeClass('mdl-textfield__icon--blue').addClass('mdl-textfield__icon--green');
	            		e.find('.material-icons').text('check_circle');
	            	}                 
	            }        
	        });

			return false;
	    });

		/**
		 *	Youtube videó lejátszás
		 */
		$('#media-panel').on('click', '.js-media-product-videos-play', function (){
			var youtube_url = $(this).closest('.form-data-row').find('.js-media-product-videos-url-input').val();

			$.magnificPopup.open({
				items: {
					src: youtube_url
				},
				type: 'iframe',
				iframe: {
					markup: '<div class="mfp-iframe-scaler">'+
					'<div class="mfp-close"></div>'+
					'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
					'</div>', 
					patterns: {
						youtube: {
							index: 'youtube.com/', 
							id: 'v=', 
							src: '//www.youtube.com/embed/%id%?autoplay=1' 
						},
						youtube_short: {
		                  index: 'youtu.be/',
		                  id: 'youtu.be/',
		                  src: '//www.youtube.com/embed/%id%?autoplay=1'
		                }
					},
					srcAction: 'iframe_src', 
				}
			});

			return false;
	    });

		/**
		 *	Metacritic score lekérés-media-videos-blos
		 */
		$('#metacritic-url').on('keyup', function() {
			var element = $(this);

			delay(function(){
				$.ajax({
		            url: element.attr('data-load-url'),
		            type: 'POST',
		            data:  {
		            	url: element.val()
		            },           
		            success: function(data, textStatus, jqXHR) {
		            	$('.js-products-metacritic-metascore').html( data.metascore );
		            	$('.js-products-metacritic-metascore-input').val( data.metascore );

		            	$('.js-products-metacritic-userscore').html( data.userscore );            	             
		            	$('.js-products-metacritic-userscore-input').val( data.userscore );            	             
		            }        
		        });
	        }, 500 );
		});

		/**
		 * Kategória választás után a limit beállítása
		 */
		$('#category').on('change', function() {
			if ( $('#category_limit').val() == '' ) {
				$('#category_limit').val( $(this).find(':selected').attr('data-limit') );
			}
		});

		/**
		 * Availability váltás esetén a plusz mezők megjelenítése
		 */
		$('#availability').on('change', function() {
			if ( $('#availability').val() == 'limited_time' ) {
				$('.js-availability-until').show();
				$('.js-availability-quantity').hide();
			} else if ( $('#availability').val() == 'limited' ) {
				$('.js-availability-quantity').show();
				$('.js-availability-until').hide();
			} else {
				$('.js-availability-until').hide();
				$('.js-availability-quantity').hide();
			}
		});

		/**
		 * Bulletin style módosítás
		 */
		
		$('input[name="bulletin"]').on('change', function() {
			var _selected = $('input[name="bulletin"]:checked').val();

			$("[class^='js-bulletins-style-']").hide();
			$('.js-bulletins-style-' + _selected).show();
		});

		/**
		 * Sales termék választás
		 */
		productSalesAccept.on('click', function(e) {
			e.preventDefault();
			let key = $(this).data('key');

			let ids = [];
			$('#js-sales-products-picker-content table tbody input[name="delete-item[]"]').each(function(){
				if ( $(this).prop('checked') ) {
					ids.push($(this).val());
				}
			})

			if ( ids.length == 0 ) {
				sweetAlert("Oops...", "Select at least one item!", "error");
				return false;
			}

			e = $('select[name="shop_sales_items['+key+'][]"]');
			e.val(ids);
			e.change();

			updateSelectOrder(e.next('.select2').find('.ui-sortable'));

			magnific.close();
		});

		/**
		 * Sales termék szűrés
		 */
		$('#js-sales-products-filter').off('keyup');
		$('#js-sales-products-filter').on('keyup', function (){
			let _self = $(this);
			let _keyword = String(_self.val().trim().toLowerCase());

			delay(function (e) {
				$('#js-sales-products-picker-content table tbody tr').each(function(){
					let _filter = $(this).data('filter').toLowerCase();

					let contains = true;
					if (_keyword !== '') {
						$.each(_keyword.split(' '), function(index, keyword) {
							if ( _filter.includes(keyword) === false ) {
								contains = false;
							}
						});
					}

					if (contains) {
						$(this).show();
					} else {
						$(this).hide();
					}
				})
			}, 400);
		});

    }

    function initSales() {

        $('.js-product-sales-active').on('click', function() {
            var row = $(this).closest('.form-data-row');

            if( $(this).prop('checked') ) {
                row.find('.js-product-sales-datefields').addClass('fields-disabled-wrapper');
            } else {
                row.find('.js-product-sales-datefields').removeClass('fields-disabled-wrapper');
            }
        });

    }

	function updateSelectOrder(self) {
		var order = [];

		$.each(self.find('li.select2-selection__choice'), function(index, val) {
			order.push($(this).attr('data-id'));
		});

		self.parents('.mdl-textfield').find('.js-input-order-helper').val(order.join());
	}

	function delay(fn, ms) {
		let timer = 0
		return function(...args) {
			clearTimeout(timer)
			timer = setTimeout(fn.bind(this, ...args), ms || 0)
		}
	}

    return {

		init: init,
		initSales: initSales

	}

}(jQuery));
