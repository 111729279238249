/**
 * Elem törlés megvalósítása.
 */
var deleteHelper = (function($) {

    'use strict';

    var ids = [],
        targetUrl = '',
        redirectNavigationClass = '',
        redirectUrl = '';

    function init() {

        $('.js-table-delete-single').on('click', function() {
            var element = $(this);

            swal({
                title: "Are you sure?",
                text: "You will not be able to recover the deleted item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#F44336",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            },
            function(){
                ids = [];
                ids.push(element.attr('data-id'));

                targetUrl   = element.attr('href');
                redirectUrl = element.attr('data-redirect-url') || '';
                redirectNavigationClass = element.attr('data-redirect-navigation-class') || '';

                sendDeleteRequest();
            });

            return false;
        });

        $('.js-table-delete-multiple').on('click', function() {
            var element = $(this);

            ids = [];
            $('#js-data-table table tbody input[name="delete-item[]"]').each(function(){
                if ( $(this).prop('checked') ) {
                    ids.push($(this).val());
                }
            })

            if ( ids.length == 0 ) {
                sweetAlert("Oops...", "Select at least one item!", "error");
                return false;
            }

            swal({
                title: "Are you sure?",
                text: "You will not be able to recover the deleted items!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#F44336",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            },
            function(){
                targetUrl = element.attr('href');

                sendDeleteRequest();
            });

            return false;
        });

    }

    function sendDeleteRequest() {
        $.ajax({
            url: targetUrl,
            data: {ids: ids},
            type: 'POST',
            datatype: 'json',
            success: function(data, textStatus, xhr) {              
                if ( data.msg == 'ok' ) {
                    swal.close();

                    if ( redirectUrl == '' ) {
                        ajaxHelper.loadContent({
                            _url: window.location.href,
                            _force_redirect: '0',
                            _url_title: 'preserve',
                            _callback: '',
                            _container: 'js-data-table',
                            _push_state: 'false'
                        });                 
                    } else {
                        ajaxHelper.loadContent({
                            _url: redirectUrl,
                            _force_redirect: '0',
                            _url_title: $('.' + redirectNavigationClass).attr('data-title'),
                            _callback: '',
                            _container: 'js-main-container',
                            _push_state: 'true',
                            _self: $('.' + redirectNavigationClass)
                        });
                    }
                } else {
                    sweetAlert("Oops...", "Something went wrong!", "error");
                }
                    

            },
            error: function() {
                sweetAlert("Oops...", "Something went wrong!", "error");
            }
        });
    }

    return {

        init: init

    }

}(jQuery));
