var neoTooltip = (function($) {

    'use strict';

    function init() {

        $('.neo-tooltip--simple').tooltipster({
            position: 'bottom'
        });

        $('.neo-tooltip--extended').tooltipster({
            position: 'right',
            theme: ['tooltipster-red']
        });

    }


    return {

        init: init

    }

}(jQuery));