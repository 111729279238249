
/**
 * Listák további szűrését, keresését lehetővé tevő script.
 */

var SearchPanel = function SearchPanel() {

    var defaults = {},
        searchPanel = $('.search-panel'),
        searchPanelMenu = $('.search-panel .mdl-menu'),
        searchPanelMenuToggler = $('.search-panel__icon-after'),
        searchPanelTags = $('.search-panel-tags'),
        searchPanelInput = $('#user-search-input'),
        searchPanelPopovers = $('.search-panel-popovers'),
        dataTableOverlay = $('.data-table-overlay');

    /**
     * Resets
     */ 
    searchPanelMenu.empty();
    searchPanelTags.empty();
    searchPanelPopovers.empty();


    /**
     * Az alapértelmezett parameétrek kibővítése. (Jelenleg nincs funkciója.)
     */
    if (arguments[0] && typeof arguments[0] === "object") {
        this.options = _extendDefaults(defaults, arguments[0]);
    }


    /**
     * Üres paraméterlista esetén visszatérünk.
     */
    if(typeof defaults.fields === 'undefined') {
        return;
    }


    /**
     * Keresőhöz tartozó plusz UI elemek bekapcsolása.
     */
    searchPanelMenu.addClass('is-visible');
    searchPanelMenuToggler.addClass('is-visible');
    searchPanelTags.addClass('is-visible');


    /**
     * Inicializálás a megadott paraméter lista alapján. (Checkbox-ok létrehozása.)
     */
    function init() {

        $(defaults.fields).each(function(index, value) {

            // Checkbox létrehozása, kibővítése paraméter lista adataival
            var newListItem = $('<label class="mdl-checkbox mdl-js-checkbox mdl-menu__item" data-type="' + defaults.fields[index].type + '" data-slug="' + defaults.fields[index].slug + '" data-name="' + defaults.fields[index].name + '" for="' + defaults.fields[index].slug + '">' +
                                    '<input type="checkbox" id="' + defaults.fields[index].slug + '" class="mdl-checkbox__input">' +
                                    '<span class="mdl-checkbox__label">' + defaults.fields[index].name + '</span>' +
                              '</label>');

            searchPanelMenu.append(newListItem);

            var addedNewListItem = searchPanelMenu.find('.mdl-checkbox').last();

            // Alapértelmezett mezők beállítása
            var urlArguments = _getQueryString();
            if ( typeof defaults.fields[index].default !== "undefined" && defaults.fields[index].default === true && typeof urlArguments.filter_keyword === "undefined" && typeof urlArguments.filter_fields === "undefined" ) {
                addedNewListItem.addClass('is-checked');
                addedNewListItem.find('input').prop('checked', true);

                /*if(helperObj.type === 'select') {
                    var data = _setParamsFromUrlSelectHelper(item, helperObj.index);

                    _addTag(addedNewListItem.attr('data-slug'), addedNewListItem.attr('data-name'), helperObj.type, data);
                } else {*/
                    _addTag(addedNewListItem.attr('data-slug'), addedNewListItem.attr('data-name'));
                /*}*/
            }

            if(defaults.fields[index].type === 'select') {
                _createPopover(value);
            }

        });

    }

    init();


    /**
     * Popover elemek elkészítése a megfellelő típusokhoz.
     * @param  {object} item [Általános elem objektum.]
     */
    function _createPopover(item) {

        if (settingsDebug) console.log("%cPopover object: " + item, "color: white; background: #ff7600; padding: 2px;");

        var newPopover = $('<div class="search-panel-popover" data-slug="' + item.slug + '" data-name="' + item.name + '" data-type="' + item.type + '">' +
                            '<div class="search-panel-popover__btns">' +
                                '<button type="button" class="js-popover-action-discard mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-color--red-500 mdl-button--mini-fab">' +
                                  '<i class="material-icons">close</i>' +
                                '</button>' +
                                '<button type="button" class="js-popover-action-approve mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-color--green-500 mdl-button--mini-fab">' +
                                  '<i class="material-icons">check</i>' +
                                '</button>' +
                            '</div>' +
                        '</div>');

        searchPanelPopovers.append(newPopover);

        
        if(item.type === 'select') {
            _createPopoverSelectHelper(item);
        }
        
    }


    /**
     * Popover elemek megjelenítéséért felelős kódrészlet
     * @param  {string} slug [Megjelenítendő elem slug-ja.]
     * @param  {string} name [Megjelenítendő elem név értéke.]
     */
    function _openPopover(slug) {
        dataTableOverlay.addClass('is-visible');

        $('.search-panel-popover[data-slug="' + slug + '"]').addClass('is-visible');
    }


    /**
     * Popover elemek elrejtéséért felelős kódrészlet
     * @param  {object} _selft [this objektum]
     */
    function _closePopover(_self) {
        dataTableOverlay.removeClass('is-visible');

        if($('.search-panel-tag[data-slug="' + _self.parents('.search-panel-popover').attr('data-slug') +'"]').length !== 1) {
            searchPanelMenu.find('[data-slug="' + _self.parents('.search-panel-popover').attr('data-slug') + '"]').removeClass('is-checked');
            searchPanelMenu.find('[data-slug="' + _self.parents('.search-panel-popover').attr('data-slug') + '"]').find('input').prop('checked', false);  
        }

        _self.parents('.search-panel-popover').removeClass('is-visible');
    }


    /**
     * Segéd függvény a select típusú popoverek elkészítéséhez.
     * @param  {object} item [Általános elem objektum.]
     */
    function _createPopoverSelectHelper(item) {

        var selectField = $('<div class="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">' +
                              '<label class="mdl-textfield__label" for="status">' + item.name + '</label>' +
                          '</div>');

        var selectItem = $('<select class="mdl-textfield__input" id="' + item.slug + '"></select>');

        $(item.options).each(function(index, value) {
            var optionItem = $('<option value="'+ value.value +'">'+ value.name +'</option>');

            selectItem.append(optionItem);
        });

        selectField.append(selectItem);

        selectField.insertBefore('[data-slug="' + item.slug + '"] .search-panel-popover__btns');
    }


    /**
     * Query string paraméterek kinyeréséhez szükséges helper függvény.
     */
    function _getQueryString () {
        var query_string = {};
        var query = window.location.search.substring(1);
        var vars = query.split("&");

        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
           
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = decodeURIComponent(pair[1]);
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
                query_string[pair[0]] = arr;
            } else {
                query_string[pair[0]].push(decodeURIComponent(pair[1]));
            }
        } 

        return query_string;
    };


    /**
     * Segéd függvény az alap paraméterek kibővítéséhez.
     */
    function _extendDefaults(source, properties) {
        var property;

        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }

    function getPathFromUrl(url) {
      return url.split("?")[0];
    }

    /**
     * Megfelelő URL összeállítása a keresési kifejezés és a paraméter lista alapján.
     * @param  {object} This érték tovább mentése a loadContent függvény számára.
     */
    function _composeURL(_self) {

        // http://admin.neocoregames.com.w5/users?filter_keyword=30&filter_fields=id

        var url = window.location.href,
            textInputValue = $('#user-search-input').val(),
            simpleFieldsList = [],
            hasTag = false;

        url = getPathFromUrl(url);

        if(textInputValue) {
            url = ajaxHelper.addUrlParameter(url, 'filter_keyword', textInputValue, false);
        } else {
            url = ajaxHelper.removeUrlParameter(url, 'filter_keyword');
        }

        searchPanelTags.find('.search-panel-tag').each(function(index, value) {
            hasTag = true;

            simpleFieldsList.push($(this).attr('data-slug')); 

            if($(this).attr('data-type') === 'select') {
                url = ajaxHelper.addUrlParameter(url, 'filter_' + $(this).attr('data-slug'), $(this).attr('data-value'), false);
            }

            if (settingsDebug) console.log("%cCímke elemek: " + index, "color: white; background: #ff7600; padding: 2px;");
        });

        if(typeof simpleFieldsList[0] !== 'undefined') {
            url = ajaxHelper.addUrlParameter(url, 'filter_fields', simpleFieldsList.join(), false);
        }

        if(!hasTag) {
            url = ajaxHelper.removeUrlParameter(url, 'filter_fields');
        }

        if (settingsDebug) console.log("%cVan címke? : " + hasTag, "color: white; background: #ff7600; padding: 2px;");
        if (settingsDebug) console.log("%cFrissített URL: " + url, "color: white; background: #ff7600; padding: 2px;");

        _updateContent(url, _self);
    }


    /**
     * Táblázat újratöltése.
     * @param  {string}
     * @param  {object}
     */
    function _updateContent(url, _self) {
        ajaxHelper.loadContent({
            _url: url,
            _force_redirect: '0',
            _url_title: 'preserve',
            _callback: '',
            _container: 'js-data-table',
            _push_state: 'true',
            _self: _self
        });
    }


    /**
     * Szűrési címke hozzáadása.
     * @param {string}
     * @param {string}
     */
    function _addTag(slug, name, type, data) {
        var newListItem = '';

        if(type === 'select') {
            newListItem = $('<span class="search-panel-tag" data-slug="' + slug + '" data-type="' + type + '" data-value="' + data.value + '">' +
                                '<span class="search-panel-tag__title">' + name + ': <span class="search-panel-tag__action" data-slug="' + slug + '">' + data.name + '</span></span>' +
                                '<span class="search-panel-tag__close"><i class="material-icons">close</i></span>' +
                            '</span>');
        } else {
            newListItem = $('<span class="search-panel-tag" data-slug="' + slug + '">' +
                                '<span class="search-panel-tag__title">' + name + '</span>' +
                                '<span class="search-panel-tag__close"><i class="material-icons">close</i></span>' +
                            '</span>');
        }

        searchPanelTags.append(newListItem);
    }


    /**
     * Újratöltés esetén a paraméterek lekezelése.
     */
    function _setParamsFromUrl() {
        var vars = _getQueryString();

        if(vars.filter_fields) {
            var fieldItems = vars.filter_fields.split(',');

            fieldItems.forEach(function(item) {

                var helperObj = _setParamsFromUrlHelper(item);

                searchPanelMenu.find('[data-slug="' + item + '"]').addClass('is-checked');
                searchPanelMenu.find('[data-slug="' + item + '"]').find('input').prop('checked', true);

                if(helperObj.type === 'select') {
                    var data = _setParamsFromUrlSelectHelper(item, helperObj.index);

                    _addTag(searchPanelMenu.find('[data-slug="' + item + '"]').attr('data-slug'), searchPanelMenu.find('[data-slug="' + item + '"]').attr('data-name'), helperObj.type, data);

                } else {
                    _addTag(searchPanelMenu.find('[data-slug="' + item + '"]').attr('data-slug'), searchPanelMenu.find('[data-slug="' + item + '"]').attr('data-name'));

                }

            });
        }

        if (settingsDebug) console.log("%cVars: " + vars.filter_keyword, "color: white; background: #ff7600; padding: 2px;");
    }


    /**
     * A címke típusának lekérése az URL alapján.
     * @param {string} itemType [slug értéke]
     */
    function _setParamsFromUrlHelper(itemSlug) {
        var returnObj = {};

        $(defaults.fields).each(function(index, value) {

            if(defaults.fields[index].slug === itemSlug) {

                if(defaults.fields[index].type === 'select') {
                    returnObj.type = defaults.fields[index].type;
                    returnObj.index = index;
                }
            }

        });

        return returnObj;
    }


    /**
     * Select címke típus segéd függvénye.
     * @param {string} itemType [slug értéke]
     */
    function _setParamsFromUrlSelectHelper(item, index) {
        var returnObj = '',
            currentIndex = 0,
            vars = _getQueryString();

        $(defaults.fields[index].options).each(function(index, value) {
            if(value.value === vars['filter_' + item]) {
                currentIndex = index;
            }
        });

        var returnObj = {
            value: defaults.fields[index].options[currentIndex].value,
            name: defaults.fields[index].options[currentIndex].name
        }

        $('.search-panel-popover[data-slug="'+ item +'"] .mdl-textfield__input option[value="'+ defaults.fields[index].options[currentIndex].value +'"]').attr('selected','selected');

        return returnObj;
    }


    /**
     * MDL menu checkbox esemény figyelés.
     */
    searchPanelMenu.find('.mdl-checkbox').on('change', function(e) {

        // Popover típusok lekezelése
        if($(this).attr('data-type') === 'select' && $(this).find('input').prop('checked')) {

            _openPopover($(this).attr('data-slug'), $(this).attr('data-name'));
            
            return;

        } else {

            // Checkbox kipipálása esetén eltávolítjuk a megfelelő címkét.
            searchPanelTags.find('[data-slug="' + $(this).attr('data-slug') + '"]').remove();
            
        }

        if($(this).find('input').prop('checked')) {

            // Checkbox bepipálása esetén létrehozzuk a megfelelő címkét a kereső alatt.
            _addTag($(this).attr('data-slug'), $(this).attr('data-name'));

        } else {

            // Checkbox kipipálása esetén eltávolítjuk a megfelelő címkét.
            searchPanelTags.find('[data-slug="' + $(this).attr('data-slug') + '"]').remove();

        }

        // Egyszerű típusok lekezelése, ha üres a kereső mező
        if($(this).attr('data-type') === 'numeric' || $(this).attr('data-type') === 'text') {
            if($('#user-search-input').val() === '') {
                return;
            }
        }


        // URL újraírása az új paraméterek alapján.
        _composeURL($(this));

    });


    /**
     * Címke törlés eseményének figyelés.
     */
    searchPanel.off('click.closetag');
    searchPanel.on('click.closetag', '.search-panel-tag__close', function() {
        
        // Megfelelő checkbox kipipálása
        searchPanelMenu.find('[data-slug="' + $(this).parents('.search-panel-tag').attr('data-slug') + '"]').removeClass('is-checked');
        searchPanelMenu.find('[data-slug="' + $(this).parents('.search-panel-tag').attr('data-slug') + '"]').find('input').prop('checked', false);

        $(this).parents('.search-panel-tag').remove();

        _composeURL($(this));
    });


    /**
     * Kereső mező küldése enterrel.
     */
    searchPanel.off('keypress.entersubmit');
    searchPanel.on('keypress.entersubmit', '#user-search-input', function(e) {        
        if (e.which == 13) {
            _composeURL($(this));
            return false;
        }
    });


    /**
     * Popover bezárása.
     */
    $('.js-popover-action-discard').on('click', function(e) {
        _closePopover($(this));
    });


    /**
     * Popover jóváhagyása.
     */
    $('.js-popover-action-approve').on('click', function(e) {
        var data = '';

        $('.search-panel-tag[data-slug="' + $(this).parents('.search-panel-popover').attr('data-slug') + '"]').remove();

        if($(this).parents('.search-panel-popover').attr('data-type') === 'select') {
            data = {
                value: $(this).parents('.search-panel-popover').find('select').val(),
                name: $(this).parents('.search-panel-popover').find('select option:selected').text()
            }
        }

        _addTag($(this).parents('.search-panel-popover').attr('data-slug'), $(this).parents('.search-panel-popover').attr('data-name'), $(this).parents('.search-panel-popover').attr('data-type'), data);

        dataTableOverlay.removeClass('is-visible');
        $(this).parents('.search-panel-popover').removeClass('is-visible');

        _composeURL($(this));
    });


    $('.search-panel').on('click', '.search-panel-tag__action', function(e) {
        _openPopover($(this).attr('data-slug'));
    });

    _setParamsFromUrl();

}
